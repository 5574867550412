import React, {useContext, useState, useEffect} from 'react';
import {Button, message, Steps, InputNumber, Modal } from 'antd';
import "./SourceModal.css";
import { StoreContext } from '../../../../../Store/StoreProvider';
import pomiarZrodlaImg from '../../../../../icons/pomiar-zrodla-modal.PNG';




const SourceModal = ({open, onClose}) => {

    const { waterSourceParams, updateWaterSourceParams } = useContext(StoreContext);
    const [inputValue, setInputValue] = useState(waterSourceParams);
    const [currentStep, setCurrentStep] = useState(0);
    const [knowPrzeplyw, setKnowPrzeplyw] = useState();
    const [naczynie, setNaczynie] = useState();
    const [czas, setCzas] = useState();

    const nextStep = () => {
      setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
      setCurrentStep(currentStep - 1);
    };

    const handleInputChange = (value) => {
      setInputValue(value);
    };

    const handleNaczynieChange = (value) => {
      setNaczynie(value);
    }

    const handleCzasChange = (value) => {
      setCzas(value);
    }


    const handleOk = () => {
      let przeplyw = 0;
      if(knowPrzeplyw){
        przeplyw = inputValue;
        if(przeplyw > 0 && !isNaN(przeplyw)){
          updateWaterSourceParams(inputValue);
          message.open({
            type: 'success',
            content: `Przepływ twojego źródła wynosi: ${przeplyw} litrów na godzinę!`,
            style: {
              fontSize: '24px'
            }
          })
          onClose();
        }
        else {
          message.open({
            type: 'error',
            content: `Wprowadzono błędne dane`,
            style: {
              fontSize: '24px'
            }
          })
        }
      }
      if(!knowPrzeplyw){
        if(naczynie > 0 && czas > 0 && !isNaN(naczynie) && !isNaN(czas)){
          przeplyw = ((naczynie / czas) * 3600);
          updateWaterSourceParams(przeplyw);
          message.open({
            type: 'success',
            content: `Przepływ twojego źródła wynosi: ${przeplyw} litrów na godzinę!`,
            style: {
              fontSize: '24px'
            }
          })
          onClose();
        }
        else{
          message.open({
            type: 'error',
            content: `Wprowadzono błędne dane`,
            style: {
              fontSize: '24px'
            }
          })
        }
      }

      onClose();
    };


    useEffect(() => {
        setInputValue(waterSourceParams); // Załaduj początkową wartość ze Store, gdy modal się otwiera
      }, [waterSourceParams, open]);
    

      const steps = [
        {
          title: "Instrukcja",
          content: (
            <div className='instruction-main'>
            <img className="instruction-img" src={pomiarZrodlaImg} alt="pomiar_zrodla" />
            </div>
          ),
        },
        {
          title: "Czy znasz przepływ wody",
          content: (
            <>
              <p>Czy znasz przpływ swojego źródła wody?</p>
              <Button onClick={() => {setKnowPrzeplyw(true); setCurrentStep(currentStep + 1);}}>Tak</Button>
              <Button onClick={() => {setKnowPrzeplyw(false); setCurrentStep(currentStep + 1);}}>Nie</Button>
            </>
          ),
        },
        {
          title: "Podaj parametry źródła wody",
          content: (
            <>
            {knowPrzeplyw && (
              <>
                <p>Podaj przepływ źródła wody</p>
                  <InputNumber
                  className='source-modal-input'
                  size='large'
                  min={0}
                  defaultValue={0}
                  value={inputValue}
                  onChange={handleInputChange}
                  addonAfter={"litrów na godzinę"}
                  />
              </>
            )}
            {!knowPrzeplyw && (
              <>
                <p>Podaj pojemność naczynia</p>
                <InputNumber
                  className='source-modal-input'
                  size='large'
                  min={0}
                  defaultValue={0}
                  value={naczynie}
                  onChange={handleNaczynieChange}
                  addonAfter={"litrów"}
              />
              <p>Podaj czas napełnienia naczynia</p>
                <InputNumber
                  className='source-modal-input'
                  size='large'
                  min={0}
                  defaultValue={0}
                  value={czas}
                  onChange={handleCzasChange}
                  addonAfter={"sekund"}
                />
              </>
            )}
            </>
          )
        }
      ];

      const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
      }));
  

      return (
        <Modal
          width={900}
          title="Źródlo"
          open={open}
          onCancel={onClose} // Usunięcie standardowych przycisków Ok/Cancel
          footer={null}       // Dodajemy niestandardowe przyciski
          centered
          className='source-modal-main'
        >
          {/* Kroki */}
          <Steps 
            current={currentStep}
            items={items}
          />
  

          <div className='step-content-main'>
            {steps[currentStep].content}
          </div>
          <div className='source-modal-btn'>
            {currentStep > 0 && (
              <Button className='step-button-prev' onClick={prevStep}>
                Cofnij
              </Button>
            )}
            {currentStep < steps.length - 1 && (
              <Button type='primary' className='step-button-next' onClick={nextStep}>
                Następny
              </Button>
            )}
  
            {currentStep === steps.length - 1 && (
              <Button type='primary' onClick={handleOk}>
                Ok
              </Button>
            )}
          </div>
        </Modal>
      );
}

export default SourceModal