import React from 'react'
import {View, Text } from '@react-pdf/renderer';
const ProductsList = ({sprinklers, styles, sections}) => {


    const przylaczaPolCala = sprinklers.filter((sprinkler) => sprinkler.przylacze === 0.5).length;

    const przylaczaTrzyCzwarteCala = sprinklers.filter((sprinkler) => sprinkler.przylacze === 0.75).length;

    

    const projectPipeNeed = sections.reduce((sum, section) => {
        return sum + section.pipeNeed;
    }, 0);

    const sprinklerCounts = sprinklers.reduce((acc, sprinkler) => {
        acc[sprinkler.name] = (acc[sprinkler.name] || 0) + 1;
        return acc;
    }, {});

    const sectionsSprinklers = new Set(sprinklers.map((sprinkler)=> sprinkler.section));
    const sectionsWithSprinklers = sectionsSprinklers.size;


    const trojniki = sprinklers.length - sectionsWithSprinklers;
    const SprinklerSummary = Object.entries(sprinklerCounts).map(([name, count]) => (
        <View style={styles.tableRow}>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                    {name}
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                    {count}
                </Text>
            </View>
        </View>
    ));


    
  return (
    <View>
        
        {sprinklers.length > 0 ? (
            <View>
                <View wrap={false}>
                    <Text style={styles.sectionSummary}>
                        Zraszacze
                    </Text>

                    <View style={styles.table} wrap={false}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    Nazwa
                                </Text>
                            </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                Ilosc
                            </Text>
                        </View>
                    </View>
            
                        {SprinklerSummary}
                    </View>
                </View>


            
            <View wrap={false}>
            <Text style={styles.sectionSummary}>
            Przylaczenie zraszaczy
            </Text>

                <View style={styles.table} wrap={false}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                Nazwa
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                Ilosc
                            </Text>
                        </View>
                </View>

                {przylaczaPolCala > 0 && 
                (
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                Kolano PE 25x1/2" GZ
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                {przylaczaPolCala}
                            </Text>
                        </View>
                    </View>
                )}


                {przylaczaTrzyCzwarteCala > 0 && 
                (
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                Kolano PE 25x3/4" GZ
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                {przylaczaTrzyCzwarteCala}
                            </Text>
                        </View>
                    </View>
                )}

                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                Trójnik PE 25x25x25
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                {trojniki}
                            </Text>
                        </View>
                    </View>

            </View>
        </View>

        
        <View wrap={false}>
        <Text style={styles.sectionSummary}>
            Rura
        </Text>

        {projectPipeNeed > 0 ? 
        (<View style={styles.table} wrap={false}>
             <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            Nazwa
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            Ilosc
                        </Text>
                    </View>
                </View>


                <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                Rura PE 25mm PN6
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                {projectPipeNeed} mb
                            </Text>
                        </View>
                </View>



        </View>) : (<><Text>Brak rury</Text></>)}

        </View>


        <View wrap={false}>
        <Text style={styles.sectionSummary}>
            Skrzynka rozdzielcza
        </Text>

        <View style={styles.table} wrap={false}>
             <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            Nazwa
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            Ilosc
                        </Text>
                    </View>
                </View>


                <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                Elektrozawór Hunter PGV 100 1" GW-GW
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                {sections.length}
                            </Text>
                        </View>
                </View>

                <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                Przelot Elysee PE 25x1" GZ
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                {sections.length + 1}
                            </Text>
                        </View>
                </View>

                {sections.length > 1 && (
                    <>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    Trójnik kolektorowy 1" GZ-GZ-GW
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {sections.length - 1}
                                </Text>
                            </View>
                        </View>

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    Kolano kolektorowy 1" GZ-GW
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    1
                                </Text>
                            </View>
                        </View>
                    </>
                )}


        </View>
        </View>

        
           </View>) : 
            (<>
            <View>
                <Text>Brak zraszaczy</Text>
            </View>
            </>)}
        
        


    </View>
  )
}

export default ProductsList