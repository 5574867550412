import React from 'react'
import video2 from "../../../helpPhotos/dodawanieBudynku.mkv"
const DodawanieBudynku = () => {
    return (
    <div className='help-navigation-container'>
        <h2 className='help-navigation-title'>Dodanie Budynku</h2>

        <div className='help-navigation-content'>
            <section className='help-video-section'>
                <video controls width="100%" className='help-video'>
                    <source src={video2} type='video/mp4' />
                </video>
            </section>
            <article className="help-navigation-details">
                <h3>Opis czynności:</h3>
                <ol>
                    <li><strong>Rozwinięcie listy budynków:</strong> W celu wyboru budynku użytkownik musi rozwinąć z menu listę budynków</li>
                    <li><strong>Wybór budynku:</strong> Użytkownik klika w wybrany przez siebie rodzaj budynku</li>
                    <li><strong>Umieszczenie budynku na planie:</strong> Użytkownik wybiera miejsce umieszczenia budynku i klika lewy przycisk myszy na polu roboczym</li>
                </ol>
            </article>
        </div>
        </div>
    )
}

export default DodawanieBudynku