import React from 'react';
import "./Footer.css";
import FooterIcons from './subcomponents/FooterIcons/FooterIcons';
import FooterItem from './subcomponents/FooterItem/FooterItem';
const Footer = () =>{

    return (
        <footer>
            <div className="footer-contact">
            <FooterItem icon={'📍'} text={'Przyłęk 145a'} />
            <FooterItem icon={'✉️'} text={'biuro@jbmproject.pl'} />
            </div>
            <FooterIcons/>
        </footer>
    )
}


export default Footer;