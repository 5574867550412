import React, {useContext} from 'react';
import { Rect } from 'react-konva';
import { StoreContext } from '../../../../../Store/StoreProvider';


const FieldRectangle = () => {

    const {fieldHeight, fieldWidth, fieldSize, gridSize} = useContext(StoreContext);


    if (!fieldSize || !gridSize){
        return null;
    }

    return (
        <Rect
            x={0} 
            y={0} 
            width={fieldWidth * gridSize}
            height={fieldHeight * gridSize}
            fill="rgba(44, 200, 39, 0.5)"
            stroke="black"
            strokeWidth={2}
        />
    )

}

export default FieldRectangle;