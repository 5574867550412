import React, { useState } from 'react';
import { Card, Button, Input } from 'antd';
import "./SterownikiPage.css";
import X2 from "./images/X2_sterownik.jpg";
import ELC from "./images/sterownik_ELC.jpg";
import HC from "./images/HC_Sterownik.jpg"
import { useNavigate } from 'react-router-dom';

const { Search } = Input;

const sterowniki = [
  {
    id: 1,
    name: "Sterownik X2",
    iloscSekcji: [4, 6, 8, 14],
    typ: "stały",
    wifi: "Możliwość rozbudowy dzięki Modułowi WAND",
    imageUrl: X2,
    cechy: [
      "Sparowanie przez Bluetooth® sprawia, że łączenie się z Wi-Fi jest szybsze i łatwiejsze",
      "Można używać smartfona jako ręcznego pilota, gdy sieć Wi-Fi jest niedostępna lub gdy dostęp do sterownika jest utrudniony",
      "Można kopiować i wklejać harmonogramy oprogramowania Hydrawise® do dowolnego sterownika X2 dzięki technologii Rapid Programming™",
    ],
    daneUzytkowe: {
      napiecieWejsciowe: "230 VAC",
      napiecieWyjscioweTransformatora: "1 A",
      napiecieWyjscioweSekcji: "0,56 A",
      wyjsciePMV: "0,28 A",
      wejsciaCzujnikow: "1",
    },
  },
  {
    id: 2,
    name: "Sterownik ECO LOGIC",
    iloscSekcji: [4,6],
    typ: "stały",
    wifi: "Brak",
    imageUrl: ELC,
    cechy: [],
    daneUzytkowe: {
      napiecieWejsciowe: "230 VAC",
      napiecieWyjscioweTransformatora: "0,625 A",
      napiecieWyjscioweSekcji: "0,56A",
      wyjsciePMV: "0,56A",
      wejsciaCzujnikow: "1",
    }
  },
  {
    id: 3,
    name: "Sterownik HC",
    iloscSekcji: [6,12],
    typ: "stały",
    wifi: "Wbudowany",
    imageUrl: HC,
    cechy: [
      "umożliwia zarządzanie nawadnianiem z dowolnego miejsca za pomocą smartfona, tabletu lub komputera",
      "technologia Predictive Watering™ koryguje harmonogramy nawadniania na podstawie lokalnych danych pogodowych",
      "sterowanie z poziomu pulpitu i łatwe programowanie za pomocą ekranu dotykowego"
    ],
    daneUzytkowe: {
      napiecieWejsciowe: "120 VAC lub 230 VAC",
      napiecieWyjscioweTransformatora: "1 A",
      napiecieWyjscioweSekcji: "0,56 A",
      wyjsciePMV: "0,28 A",
      wejsciaCzujnikow: "1",
    },
  }
];

const SterownikiPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleCategoryClick = () => {
    navigate('/catalog'); // Zmień na właściwą ścieżkę do głównej strony katalogu
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredSterowniki = sterowniki.filter((sterownik) =>
    sterownik.name.toLowerCase().includes(searchTerm)
  );

  return (
    <div className="sterowniki-page-container">
      <Button type="primary" onClick={handleCategoryClick} className='back-button'>
        Wróć do katalogu
      </Button>
      <h1 className='category-header'>Katalog Sterowników</h1>
      <div className='search-bar'>
      <Input
        className='search-input'
        placeholder="Wyszukaj sterownik"
        value={searchTerm}
        onChange={handleSearchChange}
        style={{ marginBottom: '20px', maxWidth: '300px' }}
      />
      </div>
      <div className="sterowniki-list">
        {filteredSterowniki.map((sterownik) => (
          <Card
            key={sterownik.id}
            title={sterownik.name}
            className="sterownik-card"
            cover={<img alt={sterownik.name} src={sterownik.imageUrl} className="sterownik-image" />}
          >
            <p><strong>Typ:</strong> {sterownik.typ}</p>
            <p><strong>Ilość sekcji:</strong> {sterownik.iloscSekcji.join(', ')}</p>
            <p><strong>Wi-Fi:</strong> {sterownik.wifi}</p>
            <p><strong>Cechy:</strong></p>
            <ul>
              {sterownik.cechy.map((cecha, index) => (
                <li key={index}>{cecha}</li>
              ))}
            </ul>
            <p><strong>Dane użytkowe:</strong></p>
            <ul>
              <li><strong>Napięcie wejściowe transformatora:</strong> {sterownik.daneUzytkowe.napiecieWejsciowe}</li>
              <li><strong>Napięcie wyjściowe transformatora (24 VAC):</strong> {sterownik.daneUzytkowe.napiecieWyjscioweTransformatora}</li>
              <li><strong>Napięcie wyjściowe sekcji (24 VAC):</strong> {sterownik.daneUzytkowe.napiecieWyjscioweSekcji}</li>
              <li><strong>Wyjście P/MV (24 VAC):</strong> {sterownik.daneUzytkowe.wyjsciePMV}</li>
              <li><strong>Wejścia czujników:</strong> {sterownik.daneUzytkowe.wejsciaCzujnikow}</li>
            </ul>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default SterownikiPage;
