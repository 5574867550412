import React, { useState, useEffect } from "react";
import "./App.css";
import { HashRouter as Router } from "react-router-dom";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Content from "./Components/Content/Content";
import StoreProvider, { StoreContext } from "./Store/StoreProvider";
function App() {
  const [isSomethingOnScene, setIsSomethingOnScene] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isSomethingOnScene) {
        e.preventDefault();
        e.returnValue =
          "Masz niezapisane zmiany! Czy na pewno chcesz opuścić stronę?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isSomethingOnScene]);
  return (
    <StoreProvider>
      <Router>
        <Header />
        <Content
          isSomethingOnScene={isSomethingOnScene}
          setIsSomethingOnScene={setIsSomethingOnScene}
        />
        <Footer />
      </Router>
    </StoreProvider>
  );
}

export default App;
