import React from 'react';
import UzupelnienieParametrow from './JakProjektowac/UzupelnienieParametrow';
import DodanieSekcji from './JakProjektowac/DodanieSekcji';
import WyborSekcji from './JakProjektowac/WyborSekcji';
const JakProjektowac = ({ selectedSubcategory }) => {
    return (
        <div>
            <h3>{selectedSubcategory}</h3>
                {selectedSubcategory === '1. Uzupełnienie parametrów' && (
                    <UzupelnienieParametrow/>
                )}
                {selectedSubcategory === '2. Dodanie Sekcji' && (
                    <DodanieSekcji/>
                )}
                {selectedSubcategory === '3. Wybór aktywnej sekcji' && (
                    <WyborSekcji/>
                )}

    </div>
    );
};

export default JakProjektowac;
