import React from 'react'

import menu1 from "../../../helpPhotos/uzupelnienieParametrow1.JPG";
import menu2 from "../../../helpPhotos/uzupelnienieParametrow2.JPG";
import menu3 from "../../../helpPhotos/uzupelnienieParametrow3.JPG";
import menu4 from "../../../helpPhotos/uzupelnienieParametrow4a.JPG";
import menu5 from "../../../helpPhotos/uzupelnienieParametrow4b.JPG";
const UzupelnienieParametrow = () => {
  return (
    <div className='help-navigation-container'>
    <h2 className='help-navigation-title'>Uzupełnienie parametrów</h2>

    <div className='help-navigation-content'>




        <article className="help-navigation-details">
        
            <p>Aby rozpocząć efektywne projektowanie systemu nawadniania w naszej aplikacji, kluczowym krokiem jest wprowadzenie odpowiednich parametrów <strong>przepływu źródła wody.</strong> Parametry te są niezbędne do prawidłowego obliczenia zapotrzebowania na wodę oraz zapewnienia optymalnej pracy systemu nawadniania. Poniżej znajdziesz szczegółowe instrukcje, jak wypełnić wszystkie wymagane dane krok po kroku.</p>
        
        </article>

        <article className="help-navigation-details">
            <h2>1. Wybór menu:</h2>
            <img className="help-navigation-img"src={menu1} alt="Wybór menu" />
            <p>W pierwszej kolejnyści musimy przejść do menu <strong>"ŹRÓDŁO WODY"</strong></p>
        </article>

        <article className="help-navigation-details">
            <h2>2. Instrukcja</h2>
            <img className="help-navigation-img"src={menu2} alt="Wybór menu" />
            <p>Po kliknięciu menu na naszym ekranie pojawi się modal.</p>
            <p>Pierwszym krokiem modalu jest przedstawienie instrukcji opisującej jak należy wykonać pomiar</p>
        </article>

        <article className="help-navigation-details">
            <h2>3. Wybór metody wprowadzenia danych</h2>
            <img className="help-navigation-img"src={menu3} alt="Wybór menu" />
            <p>Po przejściu do następnego kroku stajemy przed wyborem metody wprowadzania danych</p>
            <p>Jeżeli dokładnie przeczytaliśmy instrukcję z poprzedniego kroku, oraz jesteśmy pewni, że dobrze dokonamy pomiaru to powinniśmy wybrać <strong>Tak</strong></p>
            <p>Jeżeli jednak mamy obawy i wolimy, aby system dokonał obliczeń za nas wybieramy opcję <strong>Nie</strong></p>
        </article>

        <article className="help-navigation-details">
            <h2>4a. Wprowadzenie danych</h2>
            <img className="help-navigation-img"src={menu4} alt="Wybór menu" />
            <p>Jeżeli wybraliśmy opcję <strong>Tak</strong> to ostatnim punktem zostanie wprowadzenie wartości przepływu</p>
        </article>

        <article className="help-navigation-details">
            <h2>4a. Wprowadzenie danych</h2>
            <img className="help-navigation-img"src={menu5} alt="Wybór menu" />
            <p>Jeżeli wybraliśmy opcję <strong>Nie</strong> to musimy uzupełnić poprawnie objętość naczynia oraz czas jego pełnienia napełnienia, aby system obliczył przepływ za nas</p>
        </article>
        
    </div>
    </div>
    )
}

export default UzupelnienieParametrow