import React from 'react'


const FooterItem = ({icon, text}) => {
    

    return(
        <div className='footer-item'>
            <span className='footer-icon'>{icon}</span>
            <span className='footer-text'>{text}</span>
        </div>
    )

}

export default FooterItem;