import React from 'react'
import video3 from "../../../helpPhotos/dodawaniePodloza.mkv"
const DodawaniePodloza = () => {
  return (
    <div className='help-navigation-container'>
        <h2 className='help-navigation-title'>Dodanie nawierzchni</h2>

        <div className='help-navigation-content'>
            <section className='help-video-section'>
                <video controls width="100%" className='help-video'>
                    <source src={video3} type='video/mp4' />
                </video>
            </section>
            <article className="help-navigation-details">
                <h3>Opis czynności:</h3>
                <ol>
                    <li><strong>Rozwinięcie listy podłoży:</strong> W celu wyboru podłoża użytkownik musi rozwinąć z menu listę podłoży</li>
                    <li><strong>Wybór podłoża:</strong> Użytkownik klika w wybrany przez siebie rodzaj podłoża</li>
                    <li><strong>Rozpoczęcie rysowania podłoża:</strong> Użytkownik wybiera miejsce umieszczenia pierwszego narożnika podłoża i klika lewy przycisk myszy na polu roboczym</li>
                    <li><strong>Rysowanie:</strong> Użytkownik umieszcza co najmniej 3 narożniki podłoża</li>
                    <li><strong>Zakończenie rysowania:</strong> Użytkownik zatwierdza podłoże klikając w ostatnio dodany narożnik <b>Zielone kółko</b></li>
                </ol>
            </article>
            <div className='help-navigation-footer'>
                <p>Wskazówka: Możesz zakończyć rysowanie podłoża klikając ENTER</p>
            </div>
            <div className='help-navigation-footer'>
                <p>Wskazówka: Budynek musi posiadać co najmniej 3 narożniki, aby zakończyć jego rysowanie</p>
            </div>
        </div>
        </div>
  )
}

export default DodawaniePodloza