import React from 'react';
import "./Baner.css"

const Baner = () => {

    return (
        <div className='baner-wrapper'>
            <h2 className='baner-h2'>Witaj w JBMproject</h2>
            <h3 className='baner-h3'>Z nami zadbasz o swój trawnik</h3>
        </div>
    )

}


export default Baner;