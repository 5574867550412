import React, { useState } from 'react'
import PodstawyAplikacji from './PodstawyAplikacji';
import DodawanieElementow from './DodawanieElementow';
import EdycjaZraszacza from './EdycjaZraszacza';
import JakProjektowac from './JakProjektowac';
import { Breadcrumb, Layout, Menu } from 'antd';


const {Sider, Content} = Layout;


const HelpPage = () => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);

    const categories = {
        podstawy: 'Podstawy aplikacji',
        dodawanieElementow: "Dodawanie elementów",
        edycjaZraszacza: "Edycja zraszacza",
        jakProjektowac: "Jak rozpocząć projekt"
    }

    const subcategories = {
        podstawy: ['Nawigacja po interfejsie', 'Korzystanie z menu'],
        dodawanieElementow: ['Dodawanie zraszaczy', 'Dodawanie budynków', 'Wstawianie nawierzchni'],
        edycjaZraszacza: ['Edycja kątu zraszania', 'Edycja Dyszy', "Edycja Rotacji"],
        jakProjektowac: ['1. Uzupełnienie parametrów','2. Dodanie Sekcji', '3. Wybór aktywnej sekcji' ]
    };

    const findCategoryBySubcategory = (subcategory) => {
        for (const categoryKey in subcategories) {
            if (subcategories[categoryKey].includes(subcategory)) {
                return categoryKey;
            }
            }
        return null;
    }
    
    
    const handleCategoryClick = (categoryKey) => {
        setSelectedCategory(categoryKey);
        const firstSubcategory = subcategories[categoryKey]?.[0] || null;
        setSelectedSubcategory(firstSubcategory);
    };
    
    const handleSubcategoryClick = (subcategory) => {
        const categoryKey = findCategoryBySubcategory(subcategory);
        if (categoryKey && categoryKey !== selectedCategory) {
            setSelectedCategory(categoryKey);
        }
        setSelectedSubcategory(subcategory);
    }

    const renderContent = () => {
        switch (selectedCategory) {
            case 'podstawy':
            return <PodstawyAplikacji selectedSubcategory={selectedSubcategory} />;
        case 'dodawanieElementow':
            return <DodawanieElementow selectedSubcategory={selectedSubcategory} />;
        case 'edycjaZraszacza':
            return <EdycjaZraszacza selectedSubcategory={selectedSubcategory} />;
        case 'jakProjektowac':
            return <JakProjektowac selectedSubcategory={selectedSubcategory} />;
        default:
            return <p>Wybierz kategorię, aby zobaczyć instrukcje.</p>;
        }
    }


    
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider width={250} className="site-layout-background">
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    style={{ height: '100%', borderRight: 0 }}
                >
            {Object.keys(categories).map((categoryKey) => (
            <Menu.SubMenu
                key={categoryKey}
                title={categories[categoryKey]}
                onTitleClick={() => handleCategoryClick(categoryKey)}
            >
            {subcategories[categoryKey]?.map((subcategory) => (
                <Menu.Item
                    key={subcategory}
                    onClick={() => handleSubcategoryClick(subcategory)}
                >
                {subcategory}
                </Menu.Item>
            ))}
            </Menu.SubMenu>
                ))}
                </Menu>
        </Sider>
            <Layout style={{ padding: '0 24px 24px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Pomoc</Breadcrumb.Item>
                    {selectedCategory && (
                        <Breadcrumb.Item>{categories[selectedCategory]}</Breadcrumb.Item>
                    )}
                    {selectedSubcategory && (
                        <Breadcrumb.Item>{selectedSubcategory}</Breadcrumb.Item>
                    )}
                </Breadcrumb>
                <Content
                    style={{
                    padding: 24,
                    margin: 0,
                    minHeight: 280,
                    backgroundColor: '#fff',
                }}
                >
                    {renderContent()}
                </Content>
            </Layout>
    </Layout>
    )
}

export default HelpPage