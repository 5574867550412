import React, { useContext } from 'react'
import { StoreContext } from '../../../../../../Store/StoreProvider'
import { DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
const EditBuilding = ({setSelectedBuilding, selectedBuilding}) => {

    const {setSelectedBuildingId,selectedBuildingId, budynki, setBudynki} = useContext(StoreContext);

    const handleOnClickDeleteBuilding = () => {
      
      const updatedBuildings = budynki.filter(budynek => budynek.id !== selectedBuildingId);
      setBudynki(updatedBuildings);
      setSelectedBuilding(null);
      setSelectedBuildingId(null);
    }

    const handleOnClickEndEditing = () => {
        setSelectedBuilding(null);
        setSelectedBuildingId(null);
    }

  return (

        <>
        <FloatButton
          shape='circle'
          type="default"
          style={{
            insetInlineEnd: 44,
            bottom: "9vh",
            backgroundColor: 'green'
          }}
          icon={<CheckOutlined />}
          onClick={handleOnClickEndEditing}
        />

        <FloatButton
          shape='circle'
          type='default'
          style={{
            insetInlineEnd: 100,
            bottom: "9vh",
            backgroundColor: 'red'
          }}
          icon={<DeleteOutlined />}
          onClick={handleOnClickDeleteBuilding}
        />
        </>
  )
}

export default EditBuilding