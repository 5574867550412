import React, { createContext, useState } from "react";

export const StoreContext = createContext(null);

const StoreProvider = ({ children }) => {
  const [waterSourceParams, setWaterSourceParams] = useState(null);
  const [fieldWidth, setFieldWidth] = useState(0);
  const [fieldHeight, setFieldHeight] = useState(0);
  const [fieldSize, setFieldSize] = useState(false);
  const [gridSize, setGridSize] = useState(25);
  const [sprinklers, setSprinklers] = useState([]);
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const [selectedSprinkler, setSelectedSprinkler] = useState(null);
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [isPipeDrawing, setIsPipeDrawing] = useState(false);
  const [pipes, setPipes] = useState([]);
  const [actualPipePoints, setActualPipePoints] = useState([]);
  const [firstPointPipe, setFirstPointPipe] = useState(false);
  const [budynki, setBudynki] = useState([]);
  const [exportedSections, setExportedSections] = useState([]);
  const [acceptPoint, setAcceptPoint] = useState(false);
  const [selectedBuildingId, setSelectedBuildingId] = useState(null);
  const updateFieldWidth = (param) => {
    setFieldWidth(param);
  };

  const updateFieldHeight = (param) => {
    setFieldHeight(param);
  };

  const updateWaterSourceParams = (param) => {
    setWaterSourceParams(param);
  };

  const updateFieldSize = (param) => {
    setFieldSize(param);
  };

  const updateGridSize = (param) => {
    setGridSize(param);
  };

  const addSprinkler = (newSprinkler) => {
    setSprinklers((prevSprinklers) => [...prevSprinklers, newSprinkler]);
    console.log(sprinklers);
    console.log(sections);
  };

  const updateSprinkler = (id, newParam) => {
    setSprinklers(
      sprinklers.map((sprinkler) =>
        sprinkler.id === id ? { ...sprinkler, ...newParam } : sprinkler
      )
    );
  };

  const updatePolygonPoints = (updatedPolygonPoints, selectedPolygon) => {
    const updatedPolygons = polygons.map((polygon) =>
      polygon.id === selectedPolygon.id
        ? { ...polygon, points: updatedPolygonPoints }
        : polygon
    );

    setPolygons(updatedPolygons);
  };

  const updateSections = (id, newParam) => {
    setSections(
      sections.map((section) =>
        section.id === id ? { ...section, ...newParam } : section
      )
    );
  };

  const deletePolygon = (selectedPolygon) => {
    const newPolygons = polygons.filter((polygon) => {
      return polygon.id !== selectedPolygon.id;
    });

    setPolygons(newPolygons);
  };

  const addSection = (newSection) => {
    setSections((prevSections) => [...prevSections, newSection]);
  };

  const deleteSection = (newSection) => {
    setSections(() => [...newSection]);
    setSelectedSection(newSection[newSection.length - 1]);
  };

  const deleteFirstPointPipe = () => {
    setFirstPointPipe(false);
  };

  const addSectionExport = (newValue) => {
    setExportedSections((prev) => [...exportedSections, newValue]);
    const s = [...exportedSections, newValue];
    console.log(s);
  };

  return (
    <StoreContext.Provider
      value={{
        waterSourceParams,
        updateWaterSourceParams,
        fieldHeight,
        updateFieldHeight,
        fieldWidth,
        updateFieldWidth,
        fieldSize,
        updateFieldSize,
        gridSize,
        updateGridSize,
        sprinklers,
        setSprinklers,
        addSprinkler,
        updateSprinkler,
        sections,
        addSection,
        selectedSection,
        setSelectedSection,
        deleteSection,
        polygons,
        setPolygons,
        updatePolygonPoints,
        deletePolygon,
        selectedPolygon,
        setSelectedPolygon,
        selectedSprinkler,
        setSelectedSprinkler,
        updateSections,
        isPipeDrawing,
        setIsPipeDrawing,
        pipes,
        setPipes,
        actualPipePoints,
        setActualPipePoints,
        firstPointPipe,
        setFirstPointPipe,
        deleteFirstPointPipe,
        budynki,
        setBudynki,
        exportedSections,
        addSectionExport,
        acceptPoint,
        setAcceptPoint,
        setSelectedBuildingId,
        selectedBuildingId,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
