import React from 'react';
import "./MainHomePage.css"

import ProjectIcon from "../../../icons/main-page-project.png";

const MainHomePage = () => {
return (
    <main className='home-page-main-section'>
        <div className='home-page-project-image'>
            <img src= {ProjectIcon} alt="home-page-project" />
        </div>
        <div className='home-page-description'>
            <h2>Stwórz swój system już dziś</h2>
            <p>Wykorzystaj naszą rozbudowaną aplikację, która umożliwi Ci w bardzo prosty sposób stworzyć swój własny projekt nawadniania dla własnego ogrodu</p>
        </div>

    </main>
)
}

export default MainHomePage;