import React, {useState, useContext, useRef} from 'react';
import "./ProjectPage.css";
import SideMenu from './subcomponents/SideMenu/SideMenu';
import ProjectStage from './subcomponents/ProjectStage/ProjectStage';
import Modals from './subcomponents/Modals/Modals';
const ProjectPage = ({isSomethingOnScene, setIsSomethingOnScene}) => {


    //Stan przechowujący aktualnie wybrane menu
    const [currentMenu, setCurrentMenu] = useState(null);
    //Stan pzrechowujący czy modal powinien być otwarty
    const [openModal, setOpenModal] = useState(false);
    


    
    const handleMenuClick = (menuKey) => {
        setCurrentMenu(menuKey);
        setOpenModal(true);
    }






    return(
        
        <div className='project-page-main'>
            <SideMenu
                currentMenu={currentMenu}
                setCurrentMenu={handleMenuClick}
            />
            <ProjectStage
                currentMenu={currentMenu}
                setCurrentMenu={setCurrentMenu}
                setIsSomethingOnScene={setIsSomethingOnScene}
                isSomethingOnScene={isSomethingOnScene}
            />
            <Modals
                currentMenu={currentMenu}
                openModal = {openModal}
                setOpenModal = {setOpenModal}
                />
        </div>
        
        
    )
}


export default ProjectPage;