import { Modal, Button } from 'antd'
import React, { useState } from 'react'

const Instrukcja = () => {

const [isModalVisible, setIsModalVisible] = useState(true);
const handleOk = () => {
    setIsModalVisible(false);
}

return (
    <>
    <Modal
        title="Instrukcja"
        visible={isModalVisible}
        onOk={handleOk}
        footer={[ <Button key="ok" type="primary" onClick={handleOk}>Ok</Button>]}
    >
    <p>Postaraj umieścić się każdą sekcję widoczną na ekranie, aby wyświetlić je w podsumowaniu projektu</p>
    </Modal>
    </>
)
}

export default Instrukcja