import React from 'react';
import facebookIcon from "../../../../icons/facebook.png";
import xIcon from "../../../../icons/x.png";
import youtubeIcon from "../../../../icons/youtube.png";
import instagramIcon from "../../../../icons/instagram.png";

const FooterIcons = () =>{


    return (
        <div className='footer-icons'>
            <a href="#" className='footer-icon-link'><img src={facebookIcon} alt="Facebook" /></a>
            <a href="#" className='footer-icon-link'><img src={xIcon} alt="x" /></a>
            <a href="#" className='footer-icon-link'><img src={youtubeIcon} alt="youtube" /></a>
            <a href="#" className='footer-icon-link'><img src={instagramIcon} alt="Instagram" /></a>
        </div>
    )
}



export default FooterIcons;