import React from 'react'
import video from "../../../helpPhotos/edycjaDyszy.mkv"
const EdycjaDyszy = () => {
    return (
    <div className='help-navigation-container'>
        <h2 className='help-navigation-title'>Edycja Dyszy zraszacza</h2>

        <div className='help-navigation-content'>
            <section className='help-video-section'>
                <video controls width="100%" className='help-video'>
                    <source src={video} type='video/mp4' />
                </video>
            </section>
            <article className="help-navigation-details">
                <h3>Opis czynności:</h3>
                <ol>
                    <li><strong>Wybór zraszacza:</strong> Spośród zraszaczy umieszcoznych na polu roboczym wybierz, który chcesz edytować klikając na niego <strong>lewym przyciskiem myszy</strong> </li>
                    <li><strong>Edytuj Dyszy zraszacza:</strong> W oknie zraszacza odnajdź pole edycji dyszy zraszacza i zmień dyszę wybierając z listy rozwijanej</li>
                    <li><strong>Zatwierdź zmiany:</strong> Zatwierdź zmiany zraszacza przyciskiem w dolnej części okna edycji zraszacza</li>
                </ol>
            </article>
            <div className='help-navigation-footer'>
                <p>Wskazówka: Im większy numer dyszy tym większy zasięg zraszacza</p>
            </div>
            <div className='help-navigation-footer-warning'>
                <p>Wskazówka: Jeżeli po zmianie dyszy zapotrzebowanie sekcji przekroczy 80% to system nie pozwoli na zmianę</p>
            </div>
        </div>
        </div>
    )
}

export default EdycjaDyszy;