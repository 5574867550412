import React, { useContext,useState, useEffect } from 'react';
import { InputNumber, Modal, Steps, Button, message } from 'antd';
import "./SizeModal.css";
import { StoreContext } from '../../../../../Store/StoreProvider';

import rozmiarDzialki from "../../../../../icons/rozmiar-dzialki-modal.png";


const SizeModal = ({open, onClose}) => {
    const { fieldHeight, updateFieldHeight, fieldWidth, updateFieldWidth, updateFieldSize, fieldSize } = useContext(StoreContext);
    const [inputHeight, setInputHeigth] = useState(fieldHeight);
    const [inputWidth, setInputWidth] = useState(fieldWidth);
    const [currentStep, setCurrentStep] = useState(0);

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };
    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleHeigthChange = (value) => {
        setInputHeigth(value);
    }
    
    const handleWidthChange = (value) => {
        setInputWidth(value);
    }

    const handleOk = () =>{
        updateFieldHeight(inputHeight);
        updateFieldWidth(inputWidth);
        if(inputHeight > 0 && inputWidth > 0){
        updateFieldSize(true);
        } else {
            updateFieldSize(false);
            message.open({
                type: 'error',
                content: 'Wymiary działki nie mogą wynosić 0',
                style: {
                    fontSize: '24px'
                }
            })
        }
        onClose();
    }


    const steps = [
        {
            title: "Instrukcja",
            content: (
                <div className='instruction-main'>
                    <img className='instruction-img' src={rozmiarDzialki} alt="rozmiar"/>
                </div>
            ),
        },
        {
            title: "Podaj wymiary działki",
            content: (
                <>
                <p>Podaj wymiary swojej działki</p>
                    <InputNumber 
                        className='input-number-size-modal' 
                        size='large' 
                        min={0} 
                        max={50} 
                        defaultValue={0} 
                        value={inputHeight}
                        addonAfter={"długość [metry]"}
                        onChange={handleHeigthChange}
                    />
                    <InputNumber 
                        className='input-number-size-modal' 
                        size='large' 
                        min={0} 
                        max={50} 
                        value={inputWidth}
                        onChange={handleWidthChange}
                        defaultValue={0} 
                        addonAfter={"szerokość [metry]"}
                    />
                <p>Aktualna długość działki: {fieldHeight}</p>
                <p>Aktualna szerokość działki: {fieldWidth}</p>
                <p  p>{fieldSize ? "Rozmiar działki ustawiony!" : "Rozmiar nie ustawiony"}</p>
                </>
            )
        }
    ]


    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    return (
        <Modal
            className='size-modal-main'
            title="Wymiary działki"
            open={open}
            width={900}
            centered
            onCancel={onClose}
            footer={null}
        >
        
        <Steps current={currentStep} items={items} />

          {/* Zawartość aktualnego kroku */}
        <div className='step-content-main'>
            {steps[currentStep].content}
        </div>

          {/* Dynamiczne przyciski */}
        <div className='source-modal-btn'>
              {/* Przyciski Cofnij i Następny */}
            {currentStep > 0 && (
                <Button className='step-button-prev' onClick={prevStep}>
                    Cofnij
                </Button>
            )}
            {currentStep < steps.length - 1 && (
                <Button type='primary' className='step-button-next' onClick={nextStep}>
                    Następny
                </Button>
            )}
            {currentStep === steps.length - 1 && (
                <Button type='primary' onClick={handleOk}>
                    Ok
                </Button>
            )}
        </div>
        </Modal>
    );
}

export default SizeModal;