import React from 'react'
import video from "../../../helpPhotos/edycjaRotacji.mkv"
const EdycjaRotacja = () => {
    return (
    <div className='help-navigation-container'>
        <h2 className='help-navigation-title'>Edycja rotacji zraszania</h2>

        <div className='help-navigation-content'>
            <section className='help-video-section'>
                <video controls width="100%" className='help-video'>
                    <source src={video} type='video/mp4' />
                </video>
            </section>
            <article className="help-navigation-details">
                <h3>Opis czynności:</h3>
                <ol>
                    <li><strong>Wybór zraszacza:</strong> Spośród zraszaczy umieszcoznych na polu roboczym wybierz, który chcesz edytować klikając na niego <strong>lewym przyciskiem myszy</strong> </li>
                    <li><strong>Edytuj kąt zraszania:</strong> W oknie zraszacza odnajdź pole edycji kąta zraszania i zmień wartość przesuwając ssuwak, lub wpisując wartość liczbową </li>
                    <li><strong>Zatwierdź zmiany:</strong> Zatwierdź zmiany zraszacza przyciskiem w dolnej części okna edycji zraszacza</li>
                </ol>
            </article>
            <div className='help-navigation-footer-warning'>
                <p>Uwaga: Minimalna i maksymalna wartość, którą możemy ustawiać jest zgodna z parametrami podanymi przez producenta i może się różnić dla różnych modeli zraszaczy</p>
            </div>
        </div>
        </div>
    )
}

export default EdycjaRotacja;