import React from 'react'
import {View, Text, Image } from '@react-pdf/renderer';



const SectionsDescriptions = ({sections,sprinklers, styles}) => {

   

    const sectionSummary = sections.map((section) => {
        const sprinklersInSection = sprinklers.filter(sprinkler => sprinkler.section === section.name);
        const sprinklersList = sprinklersInSection.map(sprinkler => {
            return (
                <View>
                    <Text style={styles.sectionSummarySprinkler}>
                        Zraszacz: {sprinkler.name} - dysza {(sprinkler.actualDysza.number)}
                    </Text>
                </View>
            )
        })

        

        
        
        
        const sprinklerCounts = sprinklersInSection.reduce((acc, sprinkler) => {
            acc[sprinkler.name] = (acc[sprinkler.name] || 0) + 1;
            return acc;
        }, {});
    
    
        const SprinklerSummary = Object.entries(sprinklerCounts).map(([name, count]) => (
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {name}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {count}
                    </Text>
                </View>
            </View>
        ));
        

        return (
            <>
            <View>
                
                <View style={styles.section}>
                    <Text style={styles.sectionName}>{section.name}</Text>
                    {sprinklersList}
                </View>

               
                <View style={styles.sectionSummary}>
                    <Text>
                        Zraszacze: 
                    </Text>
                </View>
           
            
                <View style={styles.table} wrap={false}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                nazwa
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                ilosc
                            </Text>
                        </View>
                    
                    </View>
                    {SprinklerSummary}
           
                </View>
           
           </View>
           </>
        )
    })
  return (
    <>
    {sectionSummary}
    </>
  )
}

export default SectionsDescriptions