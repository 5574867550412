import React from 'react';
import Menu from './Menu/Menu';
import Baner from './Baner/Baner';
import MainHomePage from "./MainHomePage/MainHomePage";
import "./HomePage.css"
const HomePage = () => {

    return(
        <div className='home-page-main'>
        <Baner/>
        <Menu/>
        <MainHomePage/>
        </div>
    )
}


export default HomePage;