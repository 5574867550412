import React, { useState, useRef, useContext, useEffect } from 'react';
import { Stage, Layer, Line, Image } from 'react-konva';
import FieldRectangle from './subcomponents/FieldRectangle';
import { StoreContext } from '../../../../Store/StoreProvider';
import EditSprinkler from './subcomponents/EditSprinkler/EditSprinkler';
import Sprinklers from './subcomponents/Sprinklers/Sprinklers';
import Podloze from './subcomponents/Podloze';
import tuja from "../../../../icons/tuja.png";
import kwiaty from "../../../../icons/kwiaty.png";
import dom from "../../../../icons/dach.png"
import Buildings from './subcomponents/Buildings/Buildings';
import taras from "../../../../icons/taras.png"
import kostka from "../../../../icons/kostka.png"
import {UndoOutlined, FilePdfOutlined } from '@ant-design/icons';
import "./ProjectStage.css"
import ActiveSection from './subcomponents/ActiveSection/ActiveSection';
import EditPolygon from './subcomponents/EditPolygon/EditPolygon';
import { FloatButton, message } from 'antd';
import Pipes from './subcomponents/Pipes';
import EditBuilding from './subcomponents/EditBuilding/EditBuilding';
import { useNavigate } from 'react-router-dom';

const MAGNETIC_DISTANCE = 10;
const MIN_SCALE = 0.5;
const MAX_SCALE = 2.5;

const ProjectStage = ({currentMenu, setCurrentMenu, setIsSomethingOnScene, isSomethingOnScene}) => {

  //Pobranie z contextu danych
  const {gridSize,sprinklers,  budynki,setBudynki,actualPipePoints,setActualPipePoints,firstPointPipe,setFirstPointPipe, pipes, setPipes,isPipeDrawing,  waterSourceParams,sections, updateSections, addSprinkler, polygons, setPolygons, selectedSection, selectedPolygon, selectedSprinkler, setSelectedSprinkler} = useContext(StoreContext); 
  
  //Pozycja sceny
  const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 });
  
  // Punkty aktualnie rysowanego podłoża
  const [polygonPoints, setPolygonPoints] = useState([]); // Punkty aktualnie rysowana podłoża
  
  //Czy aktualnie rysowane jest podłoże
  const [isDrawing, setIsDrawing] = useState(false);

  //Punkt startowy linii z długością boku
  const [firstPoint, setFirstPoint] = useState(null);

  //Punkty linii długości boku
  const [distanceLine, setDistanceLine] = useState([]);

  //Wybrane podłoże
  const [selectedPodloze, setSelectedPodloze] = useState(null); 


  // Wymiar sceny (aby mógł się zmieniać wraz ze zmianą wielkości okna przeglądarki)
  const [stageSize, setStageSize] = useState ({
    width: window.innerWidth,
    height: window.innerHeight,
  })


  const [scale, setScale] = useState(1);

  
  


  // Wybrany budynek z menu
  const [selectedBuilding, setSelectedBuilding] = useState(null); 

  const navigate = useNavigate();

  

 
  const transformerRef = useRef(null);

  
  
  const stageRef = useRef(null);
  const [isStageInitialized, setIsStageInitialized] = useState(false); 


  
  //Hook, który w razie 'resize' (zmiany wielkości) okna przeglądarki zmieni również rozmiar sceny
  useEffect(() => {
    const handleResize = () => {
      setStageSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };


    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    }
  });

  useEffect(() => {
    // Sprawdzamy, czy stageRef.current jest gotowy
    if (stageRef.current) {
      setIsStageInitialized(true); // Scena jest zainicjalizowana, możemy renderować siatkę
    }
  }, [stageRef.current]);





  //Hook, który po kliknięciu "ENTER" kończy rysowanie podłoża
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter'){
        finishDrawing();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  });


  const handleWheel = (e) => {
    e.evt.preventDefault();
    const stage = stageRef.current;
    const oldScale = stage.scaleX(); // Pobieranie starej skali

    // Pozycja kursora względem sceny
    const pointer = stage.getPointerPosition();
    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    // Wyliczanie nowej skali (uwzględniamy, czy scroll jest w górę czy w dół)
    let newScale = oldScale - e.evt.deltaY * 0.001;
    newScale = Math.max(MIN_SCALE, Math.min(MAX_SCALE, newScale)); // Ograniczenie skali

    // Wyliczanie nowych współrzędnych, aby scena "podążała" za kursorem
    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };

    setScale(newScale); // Ustawienie nowej skali
    setStagePosition(newPos); // Ustawienie nowej pozycji
    stage.scale({ x: newScale, y: newScale });
    stage.position(newPos);
    stage.batchDraw();
  };
  


  const handleMouseMoveDistanceLine = (e) => {
    if(isDrawing && firstPoint || isPipeDrawing && firstPoint) {
      const stage = e.target.getStage();
      const pointerPosition = stage.getPointerPosition();
      let newX = (pointerPosition.x - stage.x()) /stage.scaleX();
      let newY = (pointerPosition.y - stage.y()) / stage.scaleY();


      if(Math.abs(newX - firstPoint.x) < MAGNETIC_DISTANCE){
        newX = firstPoint.x;
      }

      if(Math.abs(newY - firstPoint.y) < MAGNETIC_DISTANCE){
        newY = firstPoint.y;
      }
      setDistanceLine([firstPoint.x, firstPoint.y, newX , newY])
    }
  };


  // Funkcja, która pobiera dodawany obiekt, w zależności od aktywnego menu
  const getAddingType = (menuKey) => {
    switch (menuKey) {
      case 'pgpUltra':
        return (
          {
            type: 'sprinkler', 
            name: "PGP Ultra", 
            minAngle: 30, 
            maxAngle: 360, 
            color: selectedSection ? selectedSection.color : "" , 
            section: selectedSection ? selectedSection.name : "",
            przylacze: 0.75,
            dysze: [
              {number: 1.5, range: 9.5, waterNeed: 380  }, 
              {number: 2, range:10.4, waterNeed: 470}, 
              {number: 2.5, range:10.7, waterNeed: 580}, 
              {number: 3, range:11.9, waterNeed: 740},
              {number: 4, range: 12.2, waterNeed: 970},
              {number: 5, range: 12.6, waterNeed: 1240},
              {number: 6, range: 13.1, waterNeed: 1470},
              {number: 8.0, range: 13.7, waterNeed: 1950},
              ] })
      case 'srm':
        return( 
          {
            type: 'sprinkler', 
            name: "SRM", 
            minAngle: 45, 
            maxAngle: 360, 
            color: selectedSection ? selectedSection.color : "black" , 
            section: selectedSection ? selectedSection.name : "",
            przylacze: 0.5,
            dysze: [
              {number: 0.5, range: 4.3, waterNeed: 110  }, 
              {number: 0.75, range: 5.2, waterNeed: 160}, 
              {number: 1, range: 6.1, waterNeed: 210}, 
              {number: 1.5, range: 7, waterNeed: 320},
              {number: 2, range: 7.9, waterNeed: 430},
              {number: 3, range: 8.5, waterNeed: 640},
              ]
              })
      case 'pgj':
        return (
          {
            type: 'sprinkler', 
            name: "PGJ", 
            minAngle: 45, 
            maxAngle: 360, 
            minRange: 5, 
            maxRange: 8, 
            color: selectedSection ? selectedSection.color : "black" , 
            section: selectedSection ? selectedSection.name : "",
            przylacze: 0.5,
            dysze: [
              {number: 0.75, range: 4.9, waterNeed: 160}, 
              {number: 1 , range: 5.5, waterNeed: 210}, 
              {number: 1.5, range: 6.4, waterNeed: 320}, 
              {number: 2.0, range: 7.3, waterNeed: 420}, 
              {number: 2.5, range: 8.2, waterNeed: 540}, 
              {number: 3.0, range: 9.1, waterNeed: 640}, 
              {number: 4.0 , range: 10.1 , waterNeed: 890},
              {number: 5.0, range: 11, waterNeed: 1110}, 
              ]
              })
      case 'trawa':
        return {type: "podloze", color: "rgba(44, 200, 39, 0.5)"};
      case 'bruk':
        return {type: "podloze", color: "#222"};
      case 'rabata':
        return {type: "podloze", color: "saddlebrown"}
      case 'dom':
        return {type: 'budynek', texture: dom}
      case 'taras':
        return {type: 'budynek', texture: taras}
      case 'kostka':
        return {type: 'budynek', texture: kostka}
      default:
        return null;
    }
  }
    
  //Funkcja obsługująca kliknięcie sceny
  const handleStageClick = (e) => {


    // Pobranie wstawianego obiektu
    const selectedType = getAddingType(currentMenu);
    
    const stage = stageRef.current;
    
    const pointerPosition = stage.getPointerPosition();

    let newX = (pointerPosition.x - stage.x()) / stage.scaleX();
    let newY = (pointerPosition.y - stage.y()) / stage.scaleY();

    
    if(isPipeDrawing){
      const pipesInSection = pipes.filter(pipe => pipe.section === selectedSection.name);
      if(!firstPointPipe && pipesInSection.length === 0){
        setFirstPointPipe(true);
        setActualPipePoints([newX,newY])
        setFirstPoint({x: newX, y: newY})
      }
      else if(firstPointPipe) {

        if(Math.abs(newX - firstPoint.x) < MAGNETIC_DISTANCE){
          newX = firstPoint.x;
        }
  
        if(Math.abs(newY - firstPoint.y) < MAGNETIC_DISTANCE){
          newY = firstPoint.y;
        }
        const updatedPoints = [...actualPipePoints, newX,newY]
        
        const deltaX = updatedPoints[2] - updatedPoints[0];
        const deltaY = updatedPoints[3] - updatedPoints[1];
        const length = parseFloat((Math.sqrt(deltaX * deltaX + deltaY * deltaY) / gridSize).toFixed(2));
        setPipes([...pipes, {section: selectedSection.name, points: updatedPoints, length: length}]);
        setActualPipePoints([]);
        setFirstPointPipe(null);
        setFirstPoint(null);
        
        selectedSection.pipeNeed =  parseInt(selectedSection.pipeNeed + length + 1);
        updateSections(selectedSection);
        
        
      }

      




      return;

    }

    if(!selectedType) {
      return;
    }


    // Rozpoczęcie rysowania podłoża
    else if(selectedType.type === 'podloze'){
      setSelectedPodloze(getAddingType(currentMenu));

      // Ustawienie isDrawning na true
      setIsDrawing(true);
      if(!firstPoint) {
        setFirstPoint({x :newX, y:newY});
        setPolygonPoints([...polygonPoints, newX, newY]);
      } else{
        if(Math.abs(newX - firstPoint.x) < MAGNETIC_DISTANCE){
          newX = firstPoint.x;
        }
  
        if(Math.abs(newY - firstPoint.y) < MAGNETIC_DISTANCE){
          newY = firstPoint.y;
        }
        setPolygonPoints([...polygonPoints, newX, newY]);
        setFirstPoint({ x: newX, y: newY});
        setDistanceLine([]);
        
      }
    }
    

    // Wstawienie zraszacza
    else if(selectedType.type === 'sprinkler'){

            //Pobranie rodzaju pobranego zraszacza
      const actualSprinkler = getAddingType(currentMenu);

      
      if(sections.length === 0){
        message.open({
          type: 'error',
          content: 'Brak sekcji!',
          style: {
            fontSize: '24px'
          }
        })

        return;

      }

      if(!selectedSection) {
        message.open({
          type: 'error',
          content: 'Brak wybranej sekcji!',
          style: {
            fontSize: '24px'
          }
        })

        return;
      }
      
      if(!waterSourceParams) {
        message.open({
          type: 'error',
          content: 'Uzupełnij parametry źródła wody!',
          style: {
            fontSize: '24px'
          }
        })

        return;
      }

      if((selectedSection.waterNeed + actualSprinkler.dysze[0].waterNeed) > 0.8 * waterSourceParams) {
        message.open({
          type: 'error',
          content: 'Wybrana sekcja przekracza 80% przepływu źródła wody',
          style: {
            fontSize: '24px'
          }
      })

      return;
    }
      

      if(actualSprinkler) {

    

        // Zapisanie danych zraszacza do obiektu
        const newSprinkler = {
          id: Date.now(),
          x: newX,
          y: newY,
          type: actualSprinkler.type,
          angle: 90,
          minAngle: actualSprinkler.minAngle,
          maxAngle: actualSprinkler.maxAngle,
          range: actualSprinkler.dysze[0].range,
          color: actualSprinkler.color,
          rotation: 0,
          name: actualSprinkler.name,
          section: actualSprinkler.section,
          dysze: actualSprinkler.dysze,
          actualDysza: actualSprinkler.dysze[0],
          reduction: 0,
          przylacze: actualSprinkler.przylacze
        }

        selectedSection.waterNeed += actualSprinkler.dysze[0].waterNeed;

        addSprinkler(newSprinkler);
        setIsSomethingOnScene(true);
        updateSections(selectedSection);
        setCurrentMenu(null);
      }
    }


    // Dodanie nowego budynku 
    else if(selectedType.type === 'budynek'){

      const imageObj = new window.Image();

      imageObj.src = selectedType.texture;

      imageObj.onload = () => {
        const newBudynek = {
          id: Date.now(),
          x: newX,
          y: newY,
          image: imageObj,
          rotation: 0
        }

        setBudynki([...budynki, newBudynek]);
      }
      setIsSomethingOnScene(true);
      setCurrentMenu(null);
    }


  }





  // Funkcja kończąca rysowanie podłoża
  const finishDrawing = () => {
    if(polygonPoints.length >=6 ){
      const newPolygon = {
        points: polygonPoints,
        color: selectedPodloze.color,
        id: Date.now()
      };

      setPolygons([...polygons, newPolygon])
    }

    setIsSomethingOnScene(true)
    setPolygonPoints([]);
    setIsDrawing(false);
    setCurrentMenu(null);
    setSelectedBuilding(null);
    setFirstPoint(null);
  }


  

  const handleDragEnd = () => {
    const stage = stageRef.current;
    const position = stage.position();
    setStagePosition({ x: position.x, y: position.y });
  };


  // Renderowanie siatki sceny
  const renderGrid = () => {
    const lines = [];
    const stage = stageRef.current;
  
    if (!stage) return lines;
  
    const scale = stage.scaleX(); // Pobieramy aktualną skalę sceny
    const stagePosition = stage.position();
    const stageSize = {
      width: stage.width(),
      height: stage.height(),
    };
  
    // Obliczanie widocznego obszaru w odniesieniu do skali
    const topLeftX = -stagePosition.x / scale;
    const topLeftY = -stagePosition.y / scale;
    const bottomRightX = topLeftX + stageSize.width / scale;
    const bottomRightY = topLeftY + stageSize.height / scale;
  
    // Obliczamy pierwsze linie, które zaczynają się w granicach widocznego obszaru
    const firstHorizontalLine = Math.floor(topLeftY / gridSize) * gridSize;
    const lastHorizontalLine = Math.ceil(bottomRightY / gridSize) * gridSize;
    const firstVerticalLine = Math.floor(topLeftX / gridSize) * gridSize;
    const lastVerticalLine = Math.ceil(bottomRightX / gridSize) * gridSize;
  
    // Tworzymy poziome linie
    for (let y = firstHorizontalLine; y <= lastHorizontalLine; y += gridSize) {
      lines.push(
        <Line
          key={`h-${y}`}
          points={[firstVerticalLine, y, lastVerticalLine, y]}
          stroke="#888"
          strokeWidth={1 / scale} // Grubość linii proporcjonalna do skali
          listening={false}
        />
      );
    }
  
    // Tworzymy pionowe linie
    for (let x = firstVerticalLine; x <= lastVerticalLine; x += gridSize) {
      lines.push(
        <Line
          key={`v-${x}`}
          points={[x, firstHorizontalLine, x, lastHorizontalLine]}
          stroke="#888"
          strokeWidth={1 / scale} // Grubość linii proporcjonalna do skali
          listening={false}
        />
      );
    }
  
    return lines;
  };
  
  


  const handleUndoPipeClick = () => {
    const pipesInSection = pipes.filter(pipe => pipe.section === selectedSection.name);

    


    if(pipesInSection.length > 0){

      const length = pipesInSection[pipesInSection.length -1].length;

      selectedSection.pipeNeed  = parseInt(selectedSection.pipeNeed -length);

      

      const updatedPipesInSection = pipesInSection.slice(0, -1);

      const updatedPipes = pipes.filter(pipe => pipe.section !== selectedSection.name).concat(updatedPipesInSection);
      if(selectedSection.pipeNeed < 0 || updatedPipesInSection.length === 0) {
        selectedSection.pipeNeed = 0;
      }

      setPipes(updatedPipes);
      updateSections(selectedSection);
    }
  }

  const handleSummaryClick = () => {
    if (sprinklers.length === 0) {
      message.error('Aby przejść do podsumowania, musisz umieścić co najmniej jeden zraszacz na planie.');
    } else {
      navigate('/project-final');
    }
  };


  return (
    <div className="project-stage">
      <Stage
        width={stageSize.width}
        height={stageSize.height}
        draggable
        x={stagePosition.x}
        y={stagePosition.y}
        onDragEnd={handleDragEnd}
        ref={stageRef}
        onClick={handleStageClick}
        onMouseMove={handleMouseMoveDistanceLine}
        scaleX={scale} // Skalowanie w osi X
        scaleY={scale} // Skalowanie w osi Y
        onWheel={handleWheel}
      >
        <Layer>
          {renderGrid()}
          <FieldRectangle />

          <Podloze 
            selectedPodloze={selectedPodloze}
            isDrawing={isDrawing}
            polygonPoints={polygonPoints}
            distanceLine ={distanceLine}
            finishDrawing={finishDrawing}
          />

          

          


          <Buildings
            selectedBuilding={selectedBuilding}
            setSelectedBuilding={setSelectedBuilding}
            transformerRef={transformerRef}
          />



          <Sprinklers 
            setSelectedSprinkler={setSelectedSprinkler}
          />    

          <Pipes
            pipes={pipes}
            selectedSection={selectedSection}
            setFirstPoint={setFirstPoint}
            firstPoint={firstPoint}
            distanceLine={distanceLine}
          />


          
   

         
        
        
            
          

        </Layer>
        
        
 
      </Stage>

          <ActiveSection/>
      
          {selectedSprinkler && !selectedPolygon  && (
        <EditSprinkler 
          selectedSprinkler={selectedSprinkler}
          setSelectedSprinkler={setSelectedSprinkler} />
      )}


      {selectedPolygon && !isDrawing && !selectedSprinkler && (
        <EditPolygon
        selectedPolygon={selectedPolygon}
        />
      )}

      {selectedBuilding && (
        <EditBuilding
        setSelectedBuilding={setSelectedBuilding}
        selectedBuilding={selectedBuilding}
        />
      )}
      
      {isPipeDrawing && (
        <FloatButton
        shape='circle'
        type="default"
        style={{
          insetInlineEnd: "2vw",
          bottom: "9vh",
          backgroundColor: 'red',
          width: "5vw",
          height: "5vw",
        }}
        icon={<UndoOutlined />}
        onClick={handleUndoPipeClick}
        />
      )}
          <FloatButton
            className='submit-link-main'
            shape='circle'
            type="default"
            description="Podsumowanie projektu"
            icon={<FilePdfOutlined />}
            onClick={handleSummaryClick}
          />


        
      
    </div>
  );
};

export default ProjectStage;
