import React from 'react'
import "./CatalogPage.css"
import Zraszacze from "../../CatalogPhotos/Categories/Zraszacze.png"
import Sterowniki from "../../CatalogPhotos/Categories/Sterowniki.png"
import Zlaczki from "../../CatalogPhotos/Categories/Zlaczki_PE.png"
import { Navigate, useNavigate } from 'react-router-dom'
const CatalogPage = () => {
    const navigate = useNavigate();

    const handleCategoryClick = (category) => {
        navigate(`/catalog/${category}`);
    }

    return (
        <div className="katalog-container">
        <h1 className="katalog-title">Katalog Produktów</h1>
        <div className="katalog-categories">
          <div
            className="category-tile"
            onClick={() => handleCategoryClick('zraszacze')}
          >
            <img src={Zraszacze} alt="Zraszacze" />
            <h2>Zraszacze</h2>
          </div>
          <div
            className="category-tile"
            onClick={() => handleCategoryClick('sterowniki')}
          >
            <img src={Sterowniki} alt="Sterowniki" />
            <h2>Sterowniki</h2>
          </div>
          <div
            className="category-tile"
            onClick={() => handleCategoryClick('Zlaczki-pe')}
          >
            <img src={Zlaczki} alt="Złączki PE" />
            <h2>Złączki PE</h2>
          </div>
        </div>
      </div>
    )
}

export default CatalogPage