import React, { useContext, useRef, useState } from 'react'
import { StoreContext } from '../../../Store/StoreProvider'
import { Stage, Layer, Line, Image, Group, Wedge, Circle, Text, Label } from 'react-konva';
import { Button, FloatButton, message, Modal, Steps } from 'antd';
import {StepBackwardOutlined, StepForwardOutlined, CheckOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Instrukcja from './Instrukcja';
import FieldRectangle from '../subcomponents/ProjectStage/subcomponents/FieldRectangle';
const FinalProject = () => {

    const {exportedSections,  addSectionExport, pipes,gridSize,sprinklers, polygons, budynki, sections, selectedSection} = useContext(StoreContext);

    const [current, setCurrent] = useState(0);

    const [stageSize, setStageSize] = useState ({
        width: window.innerWidth,
        height: window.innerHeight,
    })

    const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 });


    const stageRef = useRef(null);

    const next = (actualSection) => {
        
        setCurrent(current + 1)
        const uri = stageRef.current.toDataURL();
        addSectionExport({image: uri,section: actualSection });
        

    };

    const addLast = (actualSection) => {
        const uri = stageRef.current.toDataURL();
        addSectionExport({image: uri,section: actualSection });
    }
    const renderGrid = () => {
        const lines = [];
    
        // Obliczanie widocznego obszaru
        const topLeftX = -stagePosition.x;
        const topLeftY = -stagePosition.y;
        const bottomRightX = topLeftX + stageSize.width;
        const bottomRightY = topLeftY + stageSize.height;
    
        const firstHorizontalLine = Math.floor(topLeftY / gridSize) * gridSize;
        const lastHorizontalLine = Math.ceil(bottomRightY / gridSize) * gridSize;
        const firstVerticalLine = Math.floor(topLeftX / gridSize) * gridSize;
        const lastVerticalLine = Math.ceil(bottomRightX / gridSize) * gridSize;
    
        for (let i = firstHorizontalLine; i <= lastHorizontalLine; i += gridSize) {
          lines.push(
            <Line
              key={`h-${i}`}
              points={[firstVerticalLine, i, lastVerticalLine, i]}
              stroke="#888"
              strokeWidth={1}
            />
          );
        }
    
        for (let i = firstVerticalLine; i <= lastVerticalLine; i += gridSize) {
          lines.push(
            <Line
              key={`v-${i}`}
              points={[i, firstHorizontalLine, i, lastHorizontalLine]}
              stroke="#888"
              strokeWidth={1}
            />
          );
        }
    
        return lines;
      };

    const handleDragEnd = () => {
        const stage = stageRef.current;
        const position = stage.position();
        setStagePosition({ x: position.x, y: position.y });
    };
    const sectionsSteps = sections.map(section => {

        const sprinklersInSection = sprinklers.filter((sprinkler) => sprinkler.section === section.name);

        const pipesInSection = pipes.filter(pipe => pipe.section === section.name);

        return {
            key: section.name,
            content: (
                <Stage
                    width={stageSize.width}
                    height={stageSize.height}
                    draggable
                    x={stagePosition.x}
                    y={stagePosition.y}
                    onDragEnd={handleDragEnd}
                    ref={stageRef}
                >
                    <Layer>
                        {renderGrid()}
                        <FieldRectangle/>
                        {polygons.map((polygon) => (
                            <Line
                                key={polygon.id}
                                points={polygon.points}
                                stroke={'black'}
                                strokeWidth={2}
                                closed={true}
                                fill={polygon.color}
                                opacity={0.9}
                            />
                        ))}

                    {budynki.map((budynek) => (
                        <Image
                        key={budynek.id}
                        x={budynek.x}
                        y={budynek.y}
                        image={budynek.image}
                        width={budynek.width || 100}
                        height={budynek.height || 100}
                        rotation={budynek.rotation || 0}
        />
        
        ))
        }

                        {sprinklersInSection.map((sprinkler) => (
                            <Group
                                key={sprinkler.id}
                                x={sprinkler.x}
                                y={sprinkler.y}
                            >
                
                
                                <Wedge
                                    radius={sprinkler.range * gridSize }
                                    angle ={sprinkler.angle}
                                    fill={sprinkler.color}
                                    stroke="black"
                                    strokeWidth={3}
                                    opacity={0.5}
                                    rotation={sprinkler.rotation}
                                />
                                <Circle
                                    radius = {0.5 * gridSize}
                                    fill={sprinkler.color}
                                    stroke={"black"}
                                    strokeWidth={2}
                                />
                
                                <Label
                                    fill={'black'}
                                >
                                    <Text
                                        text={sprinkler.name}
                                        fontFamily='Arial'
                                        fontSize={24}
                                        padding={10}
                                        fill={'white'}
                                        align='center'
                                        stroke={"black"}
                                        strokeWidth={1}

                                    />
                                </Label>

                            </Group>
                            ))}

                        

                        {pipesInSection.map((pipe) => (
                            <>
                                <Line
                                    points={pipe.points}
                                    stroke="black"
                                    strokeWidth={7}
                                />

                                <Line
                                    points={pipe.points}
                                    stroke={section.color}
                                    strokeWidth={3}
                                />
                            </>
                        ))}

                    </Layer>    
                </Stage>
            )

        }
    })

    const items = sectionsSteps.map((item) => ({
        key: item.key,
        title: item.key
    }));

    const contentStyle = {
        height: "100%",
        width: "100%",
        marginTop: 16,
    };

return (
    <>
    <Instrukcja/>
    <Steps
        current = {current}
        items={items}
    />
    <div style={contentStyle}>
        {sectionsSteps[current].content}
    </div>

    {current < sectionsSteps.length - 1 && (

        <FloatButton 
            shape='circle'
            type="default"
            style={{
                insetInlineEnd: "2vw",
                bottom: "9vh",
                backgroundColor: 'green',
                width: "70px",
                height: "70px",
            }}
            icon={<StepForwardOutlined />}
            onClick={() => next(sectionsSteps[current].key)}
        />
        
    )}
    {current === sectionsSteps.length - 1 && (
        
        <Link 
            to="/project-submit"
            style={{
                insetInlineEnd: "2vw",
                bottom: "9vh",
                width: "70px",
                height: "70px",
            }}
        >
        
            <FloatButton 
                shape='circle'
                type="default"
                style={{
                    insetInlineEnd: "2vw",
                    bottom: "9vh",
                    backgroundColor: 'blue',
                    width: "70px",
                    height: "70px",
                }}
                icon={<CheckOutlined />}
                onClick={() => addLast(sectionsSteps[current].key)}
            />

        </Link>
    )}
    </>
  )
}

export default FinalProject