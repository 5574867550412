import React, { useState } from 'react';
import { Card, Row, Col, Button } from 'antd';
import './ZraszaczePage.css';
import srmZraszacz from './images/srm_zraszacz.png';
import pgpZraszacz from './images/pgpUltra_Zraszacz.jpg';
import pgjZraszacz from './images/pgj_Zraszacz.jpg';
import { useNavigate } from 'react-router-dom';

const zraszacze = [
  {
    id: 1,
    name: 'SRM',
    brand: 'Hunter',
    description: 'Kompaktowy zraszacz rotacyjny, idealny do mniejszych przestrzeni.',
    imageUrl: srmZraszacz,
    connectionSize: 0.5,
    pressureRange: { min: 1.5, max: 3.5 },
    nozzles: [
      { number: 0.5, range: 4.3, waterNeed: 110 },
      { number: 1, range: 6.1, waterNeed: 210 },
      { number: 2, range: 7.9, waterNeed: 430 },
    ],
  },
  {
    id: 2,
    name: "PGP ULTRA",
    brand: "Hunter",
    description: 'Zraszacz rotacyjny PGP-ULTRA Hunter służy do zraszania powierzchni trawiastych na terenach prywatnych oraz publicznych i założeń komercyjnych. Zraszacz ten daje niezliczoną możliwość modyfikacji promienia ze względu na bardzo bogatą paletę dysz o różnych kątach działania.',
    imageUrl: pgpZraszacz,
    connectionSize: 0.75,
    pressureRange: {min: 1.5, max: 3.5},
    nozzles: [
      {number: 1.5, range: 9.5, waterNeed: 380  },
      {number: 2, range:10.4, waterNeed: 470},
      {number: 2.5, range:10.7, waterNeed: 580},
      {number: 3, range:11.9, waterNeed: 740},
      {number: 4, range: 12.2, waterNeed: 970},
      {number: 5, range: 12.6, waterNeed: 1240},
      {number: 6, range: 13.1, waterNeed: 1470},
      {number: 8.0, range: 13.7, waterNeed: 1950},
    ]
  },
  {
    id: 3,
    name: "PGJ",
    brand: "Hunter",
    description: 'Zraszacz rotacyjny PGJ-04 Hunter jest zraszaczem średniego zasięgu dedykowanym do zraszania trawników na terenach zieleni. Zraszacz ten jest prosty w obsłudze, a zarazem niezawodny i sprawdzony przez instalatorów. Obudowa wykonana jest z tworzywa sztucznego wysokiej jakości.',
    imageUrl: pgjZraszacz,
    connectionSize: 0.5,
    pressureRange: {min: 1.5, max: 3.5},
    nozzles: [
      {number: 0.75, range: 4.9, waterNeed: 160}, 
      {number: 1 , range: 5.5, waterNeed: 210}, 
      {number: 1.5, range: 6.4, waterNeed: 320}, 
      {number: 2.0, range: 7.3, waterNeed: 420}, 
      {number: 2.5, range: 8.2, waterNeed: 540}, 
      {number: 3.0, range: 9.1, waterNeed: 640}, 
      {number: 4.0 , range: 10.1 , waterNeed: 890},
      {number: 5.0, range: 11, waterNeed: 1110}, 
    ]
  },
  {
    id: 4,
    name: 'SRM',
    brand: 'Hunter',
    description: 'Kompaktowy zraszacz rotacyjny, idealny do mniejszych przestrzeni.',
    imageUrl: srmZraszacz,
    connectionSize: 0.5,
    pressureRange: { min: 1.5, max: 3.5 },
    nozzles: [
      { number: 0.5, range: 4.3, waterNeed: 110 },
      { number: 1, range: 6.1, waterNeed: 210 },
      { number: 2, range: 7.9, waterNeed: 430 },
    ],
  },
  {
    id: 5,
    name: "PGP ULTRA",
    brand: "Hunter",
    description: 'Zraszacz rotacyjny PGP-ULTRA Hunter służy do zraszania powierzchni trawiastych na terenach prywatnych oraz publicznych i założeń komercyjnych. Zraszacz ten daje niezliczoną możliwość modyfikacji promienia ze względu na bardzo bogatą paletę dysz o różnych kątach działania.',
    imageUrl: pgpZraszacz,
    connectionSize: 0.75,
    pressureRange: {min: 1.5, max: 3.5},
    nozzles: [
      {number: 1.5, range: 9.5, waterNeed: 380  },
      {number: 2, range:10.4, waterNeed: 470},
      {number: 2.5, range:10.7, waterNeed: 580},
      {number: 3, range:11.9, waterNeed: 740},
      {number: 4, range: 12.2, waterNeed: 970},
      {number: 5, range: 12.6, waterNeed: 1240},
      {number: 6, range: 13.1, waterNeed: 1470},
      {number: 8.0, range: 13.7, waterNeed: 1950},
    ]
  },
  {
    id: 6,
    name: "PGJ",
    brand: "Hunter",
    description: 'Zraszacz rotacyjny PGJ-04 Hunter jest zraszaczem średniego zasięgu dedykowanym do zraszania trawników na terenach zieleni. Zraszacz ten jest prosty w obsłudze, a zarazem niezawodny i sprawdzony przez instalatorów. Obudowa wykonana jest z tworzywa sztucznego wysokiej jakości.',
    imageUrl: pgjZraszacz,
    connectionSize: 0.5,
    pressureRange: {min: 1.5, max: 3.5},
    nozzles: [
      {number: 0.75, range: 4.9, waterNeed: 160}, 
      {number: 1 , range: 5.5, waterNeed: 210}, 
      {number: 1.5, range: 6.4, waterNeed: 320}, 
      {number: 2.0, range: 7.3, waterNeed: 420}, 
      {number: 2.5, range: 8.2, waterNeed: 540}, 
      {number: 3.0, range: 9.1, waterNeed: 640}, 
      {number: 4.0 , range: 10.1 , waterNeed: 890},
      {number: 5.0, range: 11, waterNeed: 1110}, 
    ]
  }
];

const ZraszaczePage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleCategoryClick = () => {
    navigate(`/catalog`);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filtrowanie zraszaczy na podstawie wyszukiwanej nazwy
  const filteredZraszacze = zraszacze.filter((sprinkler) =>
    sprinkler.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="zraszacze-page-container">
      <Button type="primary" onClick={handleCategoryClick} className="back-button">
        Wróć do katalogu
      </Button>
      <h1 className="category-header">Katalog Zraszaczy</h1>
      <div className="search-bar">
        <input
          type="text"
          className="search-input"
          placeholder="Szukaj zraszacza..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <Row gutter={[16, 16]}>
        {filteredZraszacze.map((sprinkler) => (
          <Col key={sprinkler.id} xs={24} sm={12} md={8}>
            <Card
              hoverable
              cover={<img alt={sprinkler.name} src={sprinkler.imageUrl} className="zraszacz-image" />}
              title={sprinkler.name}
              className="zraszacz-card"
            >
              <p><strong>Marka:</strong> {sprinkler.brand}</p>
              <p>{sprinkler.description}</p>
              <p><strong>Przyłącze:</strong> {sprinkler.connectionSize} cal</p>
              <p><strong>Ciśnienie:</strong> {sprinkler.pressureRange.min} - {sprinkler.pressureRange.max} bar</p>
              <h4>Dysze:</h4>
              <table className="nozzle-table">
                <thead>
                  <tr>
                    <th>Nr</th>
                    <th>Zasięg (m)</th>
                    <th>Zużycie (l/h)</th>
                  </tr>
                </thead>
                <tbody>
                  {sprinkler.nozzles.map((nozzle, index) => (
                    <tr key={index}>
                      <td>{nozzle.number}</td>
                      <td>{nozzle.range}</td>
                      <td>{nozzle.waterNeed}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ZraszaczePage;
