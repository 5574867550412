import React from 'react';
import Nawigacja from './PodstawyAplikacjiSub/Nawigacja';
import MenuNawigacja from './PodstawyAplikacjiSub/MenuNawigacja';

const PodstawyAplikacji = ({ selectedSubcategory }) => {
    return (
    <div>
        <h3>{selectedSubcategory}</h3>
        {selectedSubcategory === 'Nawigacja po interfejsie' && (
            <Nawigacja/>
        )}
        {selectedSubcategory === 'Korzystanie z menu' && (
            <MenuNawigacja/>
        )}
    </div>
    );
};

export default PodstawyAplikacji;
