import React from 'react';
import EdycjKatu from "./edycjaZraszacza/EdycjaKatu";
import EdycjaDyszy from './edycjaZraszacza/EdycjaDyszy';
import EdycjaRotacja from './edycjaZraszacza/EdycjaRotacja';
const EdycjaZraszacza = ({ selectedSubcategory }) => {
  return (
    <div>
      <h3>{selectedSubcategory}</h3>
      {selectedSubcategory === 'Edycja kątu zraszania' && (
        <EdycjKatu/>
      )}
      {selectedSubcategory === 'Edycja Dyszy' && (
        <EdycjaDyszy/>
      )}
      {selectedSubcategory === 'Edycja Rotacji' && (
        <EdycjaRotacja/>
      )}
    </div>
  );
};

export default EdycjaZraszacza;
