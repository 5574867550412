import React, { useContext } from 'react'
import { Button, message } from 'antd'
import { StoreContext } from '../../../../../../Store/StoreProvider';
import "./ActiveSection.css"
const ActiveSection = () => {
    const {pipes,setPipes,setActualPipePoints,deleteFirstPointPipe, sprinklers, setSprinklers, sections, selectedSection, deleteSection, waterSourceParams, isPipeDrawing, setIsPipeDrawing} = useContext(StoreContext);

    //Funkcja od usuwania sekcji
    const handleDeleteButton =() =>{
        //Jeżeli jest tylko 1 to uniemożliwiamy usuwanie funkcji
        if(sections.length <= 1){
            message.open({
                type: 'error',
                content: "Projekt musi posiadać co najmniej jedną sekcję",
                style: {
                    fontSize: '24px'
                }
            })
            return;
        }

        //Filtrujemy zraszacze bez zraszaczy w usuwanej sekcji

        const newSprinklers = sprinklers.filter((sprinkler) =>{ 
            return sprinkler.section !== selectedSection.name
        }
        )


        //Filtrujemy sekcje bez usuwanej sekcji

        const newSections = sections.filter((section) => {
         return   section.name !== selectedSection.name
        }
        )

        //Filtrujemy rury bez rury z usuwanej sekcji

        const newPipes = pipes.filter((pipe) => {
            return pipe.section !== selectedSection.name
        })

        setSprinklers(newSprinklers);
        deleteSection(newSections);
        setPipes(newPipes);
        
        





    }


     const handlePipeButtonClick = () => {
        if(isPipeDrawing){
            deleteFirstPointPipe();
            setActualPipePoints([]);
        }
        setIsPipeDrawing(!isPipeDrawing);
    }
  return (
    <>
    { selectedSection && (
    <div className='active-section-main'>
        
          
            <p>Aktywna sekcja: </p>
            <span style={{color: selectedSection.color}}>{selectedSection.name}</span>
            <Button onClick={handleDeleteButton} className='active-section-delete-button' type="primary" danger>Usuń sekcję</Button>
            {isPipeDrawing ? (
                <Button className='active-section-pipe-button' type="default" onClick={handlePipeButtonClick}>Zakończ rysowanie</Button>
            ) : (
                <Button className='active-section-pipe-button'type='primary'onClick={handlePipeButtonClick}>Rysuj rurę</Button>
            )}

            <p>Wykorzystanie rury:</p>
            <span>{selectedSection.pipeNeed} [m]</span>
           
{ waterSourceParams ?(
    <>
    { selectedSection.waterNeed < 0.8 * waterSourceParams ? (
            <>
                <p>Przepływ źródła wody </p>
                <span>{waterSourceParams} [l/h]</span>

            
                <p>Zapotrzebowanie sekcji</p>
                <span>{selectedSection.waterNeed} [l/h]</span>
            </>
        ) : (
            <>
                <span>Przekroczono 80% przepływu źródła</span>
            </>
            )
    }
    </>
) : (
    <>
    <span>Uzupełnij parametry źródła wody</span>
    </>
)

}
    
    </div>
    )}

        </>
  )
}

export default ActiveSection
