import React, { useContext } from 'react'
import { Menu } from 'antd';
import {ProjectOutlined, BorderOuterOutlined, BranchesOutlined, BarsOutlined} from '@ant-design/icons';
import "./MenuList.css"
import { StoreContext } from '../../../../../../Store/StoreProvider';
const MenuList = ({ currentMenu, setCurrentMenu}) => {

  const {sections, setSelectedSection,setIsPipeDrawing} = useContext(StoreContext);

  const menuSections = sections.map((section) => (
    {
      key: section.name,
      label: section.name,
    }
  ));

  const items = [
    {
      key: 'ogrod',
      label: 'PLAN OGRODU',
      icon: <ProjectOutlined/>,
      children: [
        {
          key: 'wymiary',
          label: "WYMIARY DZIAŁKI",
          icon: <BorderOuterOutlined/>
        },
        {
          key: "podloze",
          label: "PODŁOŻE",
          icon: <BarsOutlined/>,
          children: [
            {
              key: "trawa",
              label: "TRAWA"
            },
            {
              key: "bruk",
              label: "BRUK"
            },
            {
              key: "rabata",
              label: "RABATA"
            }
          ]
        },
        {
          key: "budynki",
          label: "BUDYNKI",
          icon: <BarsOutlined/>,
          children: [
            {
              key: "dom",
              label: "DOM"
            },
            {
              key: "taras",
              label: "TARAS"
            },
            {
              key: "kostka",
              label: "KOSTKA"
            }
          ]

        }
      ]
    },
    {
      key: 'nawodnienie',
      label: 'NAWODNIENIE',
      icon: <BranchesOutlined/>,
      children: [
        {
          key: 'zrodlo',
          label: "ŹRÓDŁO WODY",
          icon: <ProjectOutlined/>
        },
        {
          key: 'sekcje',
          label: "SEKCJE",
          icon: <BorderOuterOutlined/>,
          children: [
            {
              key: 'addSection',
              label: "DODAJ SEKCJĘ",
              icon: <BarsOutlined/>,
            },
            { key: 'dodaneSekcje',
              label: "MOJE SEKCJE",
              children: menuSections
            }
          ]
        },
        {
          key: 'zraszacze',
          label: 'ZRASZACZE',
          icon: <BranchesOutlined/>,
          children: [
            {
              key: 'hunter',
              label: 'Hunter',
              icon: <BarsOutlined/>,
              children: [
                {
                  key: 'pgpUltra',
                  label: 'PGP ULTRA'
                },
                {
                  key: 'srm',
                  label: "SRM"
                },
                {
                  key: 'pgj',
                  label: "PGJ"
                }
              ]
            }
          ]
        }
      ]
    }
  ]

  const onClick = (e) =>{
    setCurrentMenu(e.key);

    const clickedSection = sections.find((section) => section.name === e.key);
    if(clickedSection){
      setSelectedSection(clickedSection);
      setIsPipeDrawing(false);
    }
    
  }

    

  return (
    <>
    <Menu
      theme="dark"
      onClick={onClick}
      selectedKeys={[currentMenu]}
      mode="inline"
      items={items}
      className='project-menu-list'
    />
    </>
  )
}

export default MenuList