import React, {useState} from 'react';
import "./SideMenu.css";
import {Layout} from 'antd';
import MenuList from './subcomponents/MenuList/MenuList';



const {Sider} = Layout;
const SideMenu = ({currentMenu, setCurrentMenu}) => {
   

    return (
        <aside className="project-page-side-menu">
                <Sider 
                    theme="dark"
                    className='project-page-side-menu-sidebar'>
                    
                    <MenuList 

                        currentMenu={currentMenu} 
                        setCurrentMenu={setCurrentMenu}
                        />

                    
                </Sider>

        </aside>
    )
}

export default SideMenu;