import React, {useState, useEffect, useContext} from 'react'
import { Line, Group, Circle, Text} from 'react-konva'
import { StoreContext } from '../../../../../Store/StoreProvider'
const Podloze = ({ selectedPodloze, isDrawing, polygonPoints, distanceLine, finishDrawing}) => {


  const {isPipeDrawing, polygons, updatePolygonPoints, deletePolygon, selectedPolygon,gridSize, setSelectedPolygon} = useContext(StoreContext)

  const {selectedSprinkler} = useContext(StoreContext);
  const [anchors, setAnchors] = useState([]);
  const [distances, setDistances] = useState([]);
  const [textDistance, setTextDistance] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [actualDistance, setActualDistance] = useState(null);
  const [actualTextPosition, setActualTextPosition] = useState({x: 0, y: 0});
  const [canClosePolygon, setCanClosePolygon] = useState(false);

  useEffect(() => { 
    const handleKeyDown = (e) => {
      if (e.key === 'Enter'){
        
          setSelectedPolygon(null);
          setAnchors([]);
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  });

  useEffect(() => {
    if (polygonPoints.length >=6){
      setCanClosePolygon(true);
    } else {
      setCanClosePolygon(false);
    }
  }, [polygonPoints]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if(e.key === 'Delete'){
        if(selectedPolygon){
          deletePolygon(selectedPolygon)
          setSelectedPolygon(null);
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  })

  useEffect(() => {
    if(distanceLine.length > 0){
      const midX = ((distanceLine[0] + distanceLine[2]) /2);
      const midY = ((distanceLine[1] + distanceLine[3]) /2);
      const deltaX = distanceLine[2] - distanceLine[0];
      const deltaY = distanceLine[3] - distanceLine[1];
      const length = ((Math.sqrt(deltaX * deltaX + deltaY * deltaY)) / gridSize).toFixed(2);

      setActualTextPosition({x: midX, y: midY});
      setActualDistance(length)
    }
  }, [distanceLine]);


  const handleCircleClick = () =>{
    if(canClosePolygon) {
      finishDrawing();
      setCanClosePolygon(false);
    }
  }


  const handlePolygonClick = (polygon) => {
    if(isDrawing === false && !selectedSprinkler && !isPipeDrawing){
    setSelectedPolygon(polygon);
    const newAnchors = [];
    const linePoints = [];
    const textPlace = [];

    for (let i = 0; i < polygon.points.length; i +=2){
      newAnchors.push({id: i, x: polygon.points[i], y: polygon.points[i+1], xIndex: i, yIndex: i+1});
    }

    for(let i = 0; i+2 < polygon.points.length; i +=2){
      const points = [polygon.points[i] - 10, polygon.points[i+1] - 10, polygon.points[i+2] - 10, polygon.points[i+3] - 10];
      linePoints.push({id:i, points: points });

      const midX = ((polygon.points[i] + polygon.points[i + 2]) / 2) ;
      const midY = ((polygon.points[i + 1] + polygon.points[i + 3]) / 2); 
      const deltaX = polygon.points[i + 2] - polygon.points[i];
      const deltaY = polygon.points[i + 3] - polygon.points[i + 1];
      const length = ((Math.sqrt(deltaX * deltaX + deltaY * deltaY) ) / gridSize).toFixed(2);
      textPlace.push({id: i, x: midX, y: midY, text: length })

    }

      const pointy = [polygon.points[0] - 10, polygon.points[1] - 10, polygon.points[polygon.points.length-2] - 10, polygon.points[polygon.points.length-1] - 10];
      linePoints.push({id: polygon.points.length+1, points: pointy})


      const midX = ((polygon.points[0] + polygon.points[polygon.points.length-2]) / 2) ;
      const midY = ((polygon.points[polygon.points.length-1] + polygon.points[1]) / 2) ;
      const deltaX = polygon.points[polygon.points.length-2] - polygon.points[0];
      const deltaY = polygon.points[polygon.points.length-1] - polygon.points[1];
      const length = ((Math.sqrt(deltaX * deltaX + deltaY * deltaY) ) / gridSize).toFixed(2);
      textPlace.push({id: polygon.points.length+1, x: midX, y: midY, text: length })

    setAnchors(newAnchors);
    setDistances(linePoints);
    setTextDistance(textPlace);
  }
      
  };


  const handleDragAnchorMove = (e, anchor) => {
    const {x,y} = e.target.position();

    const updatedAnchors = anchors.map((anch) => 
    anch.id === anchor.id ? {...anch, x,y} : anch);

    setAnchors(updatedAnchors);

    const updatedPolygonPoints = [...selectedPolygon.points];
    updatedPolygonPoints[anchor.xIndex] = x;
    updatedPolygonPoints[anchor.yIndex] = y;

    updatePolygonPoints(updatedPolygonPoints, selectedPolygon);
  }


  const handleDragAnchorStart = () => {
    if(!isDragging){
      setIsDragging(true);
    }

  }

  const handleDragAnchorEnd = () => {
    setSelectedPolygon(false);
    setIsDragging(false);
  }



  
  return (
    <>
    
        {polygons.map((polygon) => (
          
          <Group>
            <Line

              key={polygon.id}
              points={polygon.points}
              stroke={'black'}
              strokeWidth={2}
              closed={true}
              fill={polygon.color}
              opacity={0.9}
              onClick={() => handlePolygonClick(polygon)}
            />

            {selectedPolygon && !isDrawing && 
            
              
              anchors.map((anchor) => (
                <Circle
                  key={anchor.id}
                  x={anchor.x}
                  y={anchor.y}
                  radius={10}
                  stroke = {'red'}
                  fill = {'black'}
                  strokeWidth={3}
                  draggable
                  onDragStart={handleDragAnchorStart}
                  onDragMove={(e) => handleDragAnchorMove(e, anchor)}
                  onDragEnd={handleDragAnchorEnd}
                  />
              ))
             
            }

            {selectedPolygon && !isDrawing && 
              textDistance.map((text) => (
                <Text
                  x={text.x}
                  y={text.y}
                  text={text.text}
                  fontSize={24}
                  fontFamily={'arial'}
                  fill={"red"}
                  

                />
              ))

            }




            {selectedPolygon &&  !isDrawing && 
              distances.map((distance) => (
                <Line
                  key={distance.id}
                  points={distance.points}
                  stroke={'black'}
                  strokeWidth={3}
                  dash={[30,30]}
                  
                />
              ))

            }
          </Group>
          ))}

            {isDrawing && (
              <Group>
              <Line
                points={polygonPoints}
                stroke={'black'}
                strokeWidth={2}
                closed={true}
                fill={selectedPodloze.color}
                opacity={0.9}
              />
              
              

              {distanceLine.length > 0 && (
                <>
                  <Line
                    points={distanceLine}
                    stroke={"black"}
                    strokeWidth={4}
                    dash={[10,10]}
                    />

                  <Text 
                    x={actualTextPosition.x}
                    y={actualTextPosition.y}
                    text={actualDistance}
                    fontSize={24}
                    fontFamily={'arial'}
                    fill={"red"}
                  />
                  </>
              )}
            {canClosePolygon && (
              <Circle 
              x={polygonPoints[polygonPoints.length -2]}
              y={polygonPoints[polygonPoints.length - 1]}
              radius={10}
              stroke={'green'}
              fill={'green'}
              strokeWidth={3}
              onClick={handleCircleClick}
            />


            )}

              
              </Group>
            )}
    </>
  )
}

export default Podloze