import React from 'react'

import menu1 from "../../../helpPhotos/dodanieSekcji1.JPG";
import menu2 from "../../../helpPhotos/wyborSekcji1.JPG";
import menu3 from "../../../helpPhotos/wyborSekcji2.JPG";

const WyborSekcji = () => {
  return (
    <div className="help-navigation-container">
        <h2 className="help-navigation-title">Dodanie Sekcji</h2>

        <div className="help-navigation-content">
        <article className="help-navigation-details">
            <p>Po uzupełnieniu parametrów źródła wody, oraz dodaniu co najmniej 1 sekcji następnym krokiem jest wybór aktywnej sekcji nawadniania.</p>
        </article>

        <article className="help-navigation-details">
            <h2>1. Otwórz menu "Sekcje"</h2>
            <img className="help-navigation-img" src={menu1} alt="Menu Sekcje" />
            <p>Aby wybrać aktywną sekcję, kliknij w menu <strong>"Sekcje"</strong>, znajdujące się w lewej nawigacji pod sekcją <strong>"Nawodnienie"</strong>. W ten sposób uzyskasz dostęp do opcji zarządzania sekcjami.</p>
        </article>

        <article className="help-navigation-details">
            <h2>2. Kliknij "Moje Sekcję"</h2>
            <img className="help-navigation-img" src={menu2} alt="Dodaj Sekcję" />
            <p>Po rozwinięciu menu sekcji, zobaczysz przycisk <strong>"Moje Sekcję"</strong>. Kliknięcie tego przycisku rozwinie się lista utworzonych sekcji</p>
        </article>

        <article className="help-navigation-details">
            <h2>3. Nazwij swoją sekcję</h2>
            <img className="help-navigation-img" src={menu3} alt="Formularz sekcji" />
            <p>W formularzu wprowadź nazwę swojej sekcji (np. <em>"Trawnik", "Rabata kwiatowa", "Ogródek warzywny"</em>). Wybierz nazwę, która pozwoli Ci łatwo zidentyfikować dany obszar na mapie.</p>
        </article>

        <article className="help-navigation-details">
            <h2>5. Zapisz sekcję</h2>
            <p>Gdy wszystkie pola formularza są poprawnie uzupełnione, kliknij przycisk <strong>"Zapisz"</strong>. Nowa sekcja zostanie dodana do projektu, a jej nazwa będzie widoczna w menu <strong>"Moje Sekcje"</strong>.</p>
        </article>

        <div className="help-navigation-footer">
            <p><strong>Wskazówka:</strong> Sekcje można edytować i usuwać w dowolnym momencie, w menu aktywnej sekcji. Dostosuj swoje sekcje do potrzeb i zmieniających się warunków w projekcie!</p>
        </div>
        </div>
    </div>
  )
}

export default WyborSekcji


