import React, {useContext, useEffect, useRef, useState} from 'react';
import {Image, Transformer } from 'react-konva';
import { StoreContext } from '../../../../../../Store/StoreProvider';

const Buildings = ({  selectedBuilding, setSelectedBuilding}) => {

    const {selectedBuildingId,setSelectedBuildingId, budynki, setBudynki, selectedSprinkler, selectedPolygon} = useContext(StoreContext);
    const transformerRef = useRef(null);
    const imageRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        if(transformerRef.current && selectedBuilding){
            transformerRef.current.nodes([selectedBuilding]);
            transformerRef.current.getLayer().batchDraw();
        }
    }, [selectedBuilding]);



    const handleTransformEnd = (e, buildingId) => {
        const node = e.target;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();
        const { x, y } = node.position();
        const rotation = node.rotation();
    
        node.scaleX(1);
        node.scaleY(1);
    
        const updatedBuildings = budynki.map((building) =>
            building.id === buildingId
                ? {
                    ...building,
                    x,
                    y,
                    width: node.width() * scaleX,
                    height: node.height() * scaleY,
                    rotation: rotation
                }
                : building
        );
    
        setBudynki(updatedBuildings);
        setSelectedBuilding(null);
        setSelectedBuildingId(null);
    };

    const handleDragEnd = (e, buildingId) => {
        const {x,y} = e.target.position();

        const updatedBuildings = budynki.map((building) =>
            building.id === buildingId ? {...building, x,y} : building
        );

        setBudynki(updatedBuildings)
        setIsDragging(false);
        setSelectedBuilding(null);
        setSelectedBuildingId(null);
    }

    const handleBuildingClick = (e, budynekId) => {
        if(!selectedSprinkler && !selectedPolygon){
            setSelectedBuilding(e.target);
            setSelectedBuildingId(budynekId);
        }
    }


    return(

    <>
        {budynki.map((budynek) => (
            <Image
            key={budynek.id}
            x={budynek.x}
            y={budynek.y}
            image={budynek.image}
            width={budynek.width || 100}
            height={budynek.height || 100}
            draggable
            rotation={budynek.rotation || 0}
            onClick={(e) => handleBuildingClick(e, budynek.id)}
            onDragEnd={(e) => handleDragEnd(e, budynek.id)}
            onDragStart={() => setIsDragging(true)}
            onTransformEnd={(e) => handleTransformEnd(e, budynek.id)}
        />
        
        ))
        }

        {selectedBuildingId && (
            <>
            <Transformer
                ref={transformerRef}
                boundBoxFunc={(oldBox, newBox) => {
                    if(newBox.width < 25 || newBox.height < 25){
                        return oldBox;
                    }
                    return newBox;
                }}
            />

            </>

        )}
    </>
    )
}

export default Buildings