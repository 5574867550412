import React, {useContext, useEffect} from 'react';
import {Route, Routes} from "react-router-dom";
import "./Content.css";
import HomePage from '../HomePage/HomePage';
import ProjectPage from '../ProjectPage/ProjectPage';
import ProjectSubmit from '../ProjectSubmit/ProjectSubmit';
import FinalProject from '../ProjectPage/FinalProject/FinalProject';
import { StoreContext } from '../../Store/StoreProvider';
import HelpPage from '../HelpPage/HelpPage';
import CatalogPage from '../CatalogPage/CatalogPage';
import ZraszaczePage from '../CatalogPage/ZraszaczePage/ZraszaczePage';
import SterownikiPage from '../CatalogPage/Sterownikipage/SterownikiPage';
import ZlaczkiPage from '../CatalogPage/ZlaczkiPage/ZlaczkiPage';


const Content = ({isSomethingOnScene, setIsSomethingOnScene}) => {

    
    return(
        <main className='Content'>
            <Routes>
                <Route exact path="/" Component={()=> <HomePage/>} />
                <Route exact path="/project" Component={() => <ProjectPage setIsSomethingOnScene={setIsSomethingOnScene} isSomethingOnScene={isSomethingOnScene} />} />
                <Route exact path="/project-submit" Component={() => <ProjectSubmit/>} />
                <Route exact path="/project-final" Component={() => <FinalProject/>} />
                <Route exact path="/Help" Component={() => <HelpPage/>}/>
                <Route exact path="/catalog" Component={() => <CatalogPage/>}/>
                <Route exact path="/catalog/zraszacze" Component={() => <ZraszaczePage/>}/>
                <Route exact path="/catalog/Sterowniki" Component={() => <SterownikiPage/>}/>
                <Route exact path="/catalog/Zlaczki-pe" Component={() => <ZlaczkiPage/>}/>
            </Routes>
        </main>
    )
}


export default Content;