import React, { useContext, useEffect, useState } from 'react'
import { Page,View,StyleSheet, Text, Document, PDFViewer, Image } from '@react-pdf/renderer';
import "./ProjectSubmit.css";
import { StoreContext } from '../../Store/StoreProvider';
import SectionsDescriptions from './subcomponents/SectionsDescriptions';
import ProductsList from './subcomponents/ProductsList';
import LoadingScreen from './subcomponents/LoadingScreen';
const styles = StyleSheet.create({
    page: {padding: 30},
    section: {color: "black", textAlign: 'center', margin: 30},
    sectionName: {color: "red", margin: 40, fontSize: 28, textAlign: 'center'},
    sectionSummary: {color: "royalblue", margin: 20, fontSize: 24, textAlign: 'center'},
    sectionSummarySprinkler: {margin: 5, fontSize: 18},


    table: {display: "table", width: "90%", margin: "0 auto", borderStyle: "solid", borderWidth: 1, borderColor: "black"},
    tableRow: {flexDirection: "row"},
    tableCol: {width: "50%", borderStyle: "solid", borderWidth: 1, borderColor: "black", padding: 5},
    tableCell: {fontSize: 18, margin: "0 auto"}

})

const ProjectSubmit = () => {

    const {sections, sprinklers, exportedSections} = useContext(StoreContext);
    console.log(exportedSections);
    const [isLoading, setIsLoading] = useState(true);
    let i = 0;
    const sectionImages = sections.map((section) => {
        
        const sectionImage = exportedSections[i];
        if(i < sections.length-1){
            i = i +1;
        }

        
        
        
    return(
        <View wrap={false}>
            <Text style={styles.sectionName}>{section.name}</Text>
            <Image src={sectionImage.image} style={{marginTop: "20px",width: '100%', height: "auto"}} />
        </View>
    )
    })

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 5000);

        return () => clearTimeout(timer);
    })



    return (
    <div className='submit-project-main'>

    {isLoading && <LoadingScreen/>}
    <PDFViewer width="100%" height="100%" >
    <Document>
       <Page size="A4" style={styles.page} >

            <View style={styles.section}>
                <Text>Podsumowanie Projektu</Text> 
            </View>
   

        {(sections.length > 0) ? 
        (
            <>
            <View style={styles.sectionSummary}>
                <Text>Ogolny spis produktow</Text>
            </View>

            <ProductsList 
                sprinklers={sprinklers}
                styles={styles}
                sections={sections}
            />

            <View>
                <Text style={styles.sectionSummary}>Sczegolowe podsumowanie sekcji</Text>
            </View>


            <SectionsDescriptions
            sections={sections}
            sprinklers={sprinklers}
            styles={styles}
            />

            
            {sectionImages}
            
            </>
        ) : (
            <>
                <Text>Brak sekcji</Text>
            </>
            ) }
           
        
       </Page>
    </Document>
    </PDFViewer>
    </div>
  )
}


export default ProjectSubmit