import React from 'react'
import "./Nawigacja.css"
import menu1 from "../../../helpPhotos/menu1.png"
import menu2 from "../../../helpPhotos/menu2.PNG"
import menu3 from "../../../helpPhotos/menu3.JPG"
const MenuNawigacja = () => {
  return (
    <div className='help-navigation-container'>
    <h2 className='help-navigation-title'>Menu projektu</h2>

    <div className='help-navigation-content'>
        <article className="help-navigation-details">
            <h2>Kategorie Menu:</h2>
            <img className="help-navigation-img"src={menu1} alt="ogolna nawigacja" />
            <p>Menu składa się z dwóch głównych kategorii, którymi są: <strong>Plan ogrodu</strong> oraz <strong>Nawodnienie</strong></p>
            <ol>
                <li><strong>Plan ogrodu:</strong> W tej kategorii znajdują się wszystkie elementy, które możemy dodać do naszego projektu, aby jak najlepiej odwzorować działkę</li>
                <li><strong>Nawodnienie:</strong> W tej kategorii znajdują się elementy nawodnieniowe, z których stworzony jest projekt. Dodatkowo możemy tu dodać nową sekcję oraz wybrać aktualnie edytowaną</li>
            </ol>
        </article>



        <article className="help-navigation-details">
            <h2>Plan ogrodu:</h2>
            <img className="help-navigation-img"src={menu2} alt="Plan ogrodu" />
            <p>Pierwsza kategoria podzielona jest na:</p>
            <ol>
                <li><strong>Wymiary działki</strong> Funkcja umożliwiająca użytkownikowi automatyczne narysowanie prostokątnego obszaru roboczego o wybranej długości oraz szerokości</li>
                <li><strong>Podłoże</strong> W tej kategorii użytkownik jest w stanie wybrać rodzaj podłoża, który chce umieścić na polu roboczym</li>
                <li><strong>Budynki</strong> W tej kategorii użytkownik jest w stanie wybrać rodzaj budynku, który chce umieścić na polu roboczym</li>
            </ol>
        </article>

        <article className="help-navigation-details">
            <h2>Nawodnienie:</h2>
            <img className="help-navigation-img"src={menu3} alt="Nawodnienie" />
            <p>Druga kategoria podzielona jest na:</p>
            <ol>
                <li><strong>Źródło wody</strong> Funkcja umożliwiająca użytkownikowi uzupełnienie parametrów źródła wody</li>
                <li><strong>Sekcjee</strong> W tej kategorii użytkownik jest w stanie dodać nową sekcję, oraz wybrać z listy <strong>Moje sekcje</strong>, którą aktualnie chce edytować</li>
                <li><strong>Zraszacze</strong> W tej kategorii użytkownik jest w stanie wybrać rodzaj zraszacza, który chce umieścić na polu roboczym</li>
            </ol>
        </article>
        <div className='help-navigation-footer'>
            <p>Wskazówka: Aktualnie wybrany element z menu jest podświetlony, przez co w łatwy sposób możemy zauważyć wybrany element </p>
        </div>
    </div>
    </div>
    )
}

export default MenuNawigacja