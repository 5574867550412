import React from 'react'
import video1 from "../../../helpPhotos/dodanieZraszacza.mkv"
const DodanieZraszacza = () => {
  return (
    <div className='help-navigation-container'>
        <h2 className='help-navigation-title'>Dodanie zraszacza</h2>

        <div className='help-navigation-content'>
            <section className='help-video-section'>
                <video controls width="100%" className='help-video'>
                    <source src={video1} type='video/mp4' />
                </video>
            </section>
            <article className="help-navigation-details">
                <h3>Opis czynności:</h3>
                <ol>
                    <li><strong>Rozwinięcie listy zraszaczy:</strong> W celu wyboru zraszacza użytkownik musi rozwinąć z menu listę zraszaczy</li>
                    <li><strong>Wybór zraszacza:</strong> Użytkownik klika w wybrany przez siebie model zraszacza</li>
                    <li><strong>Umieszczenie zraszacza na planie:</strong> Użytkownik wybiera miejsce umieszczenia zraszacza i klika lewy przycisk myszy na polu roboczym</li>
                </ol>
            </article>
            <div className='help-navigation-footer-warning'>
                <p>Uwaga: Przed rozmieszczaniem zraszaczy na planie należy pamiętać o uzupełnieniu przepływu źródła wody oraz wyborze aktywnej sekcji, do której chcemy dodać zraszacz</p>
            </div>
        </div>
        </div>
  )
}

export default DodanieZraszacza