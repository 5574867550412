import { Modal, Input, ColorPicker, message, Button } from 'antd'
import React, { useContext, useState } from 'react'
import { StoreContext } from '../../../../../Store/StoreProvider';

const AddSectionModal = ({open, onClose}) => {

  const {sections, addSection} = useContext(StoreContext);
  const [inputName, setInputName] = useState('');
  const [inputColor, setInputColor] = useState('#333');
  
  const handleNameChange = (e) => {

    

    
    setInputName(e.target.value);
    
  }

  const handleColorChange = (value) =>{
    const hexColor = value.toHexString();
    setInputColor(hexColor);
  }

  const handleOk = () => {

    const nameUnique = sections.filter((section) => {
      return section.name === inputName;
    })

    if(inputName){
      if(nameUnique.length === 0) {
      
        const newSection = {
        id: Date.now(),
        name: inputName,
        color: inputColor,
        waterNeed: 0,
        pipeNeed: 0,
       
      }
      addSection(newSection);

      onClose();
    } else{
      message.open({
        type: 'error',
        content: 'Nazwa sekcji musi być unikalna',
        style: {
          fontSize: '24px'
        }
      })
    }
    
  }
  else {
    message.open({
      type: 'error',
      content: 'Sekcja musi posiadać nazwę',
      style: {
        fontSize: '24px'
      }
    })
  }

  
  }
  return (
    <Modal
      className='add-section-modal-main'
      title="Dodaj nową sekcję"
      open={open}
      centered
      onCancel={onClose}
      footer={null}  // Usunięcie domyślnych przycisków
    >
      <p>Podaj nazwę sekcji</p>
      <Input placeholder='Nazwa sekcji' value={inputName} onChange={handleNameChange} />

      <p>Wybierz kolor dla sekcji</p>
      <ColorPicker defaultValue={"#333"} value={inputColor} onChange={handleColorChange} />

      {/* Przycisk Ok */}
      <div className='source-modal-btn'>
        <Button type='primary' onClick={handleOk}>
          Ok
        </Button>
      </div>
    </Modal>
  );
}

export default AddSectionModal;