import React from 'react';
import { Link } from 'react-router-dom';



const HeaderNavigation = () => {


    return (
        <>
        <ul>
                <Link to="/project" className='header-navigation-item'>Projektowanie</Link>
                <Link to="/catalog" className='header-navigation-item'>Katalog</Link>
                <Link to="/Help" className='header-navigation-item'>Pomoc</Link>
        </ul>
        </>
    )
}

export default HeaderNavigation;