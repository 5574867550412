import React, { useContext } from 'react'
import { DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import { StoreContext } from '../../../../../../Store/StoreProvider';
const EditPolygon = ({selectedPolygon}) => {

  const {setSelectedPolygon, deletePolygon} = useContext(StoreContext);
  const handleEndEditing = () => {
    setSelectedPolygon(null);
  }

  const handleDeletePolygon = () => {
    deletePolygon(selectedPolygon);
    setSelectedPolygon(null);
  }
  return (
    <>
    <FloatButton
      shape='circle'
      type="default"
      style={{
        insetInlineEnd: "6vw",
        bottom: "9vh",
        backgroundColor: 'green',
        width: "3vw",
        height: "3vw",
      }}
      icon={<CheckOutlined />}
      onClick={handleEndEditing}
    />

    <FloatButton
      shape='circle'
      type="default"
      style={{
        insetInlineEnd: "2vw",
        bottom: "9vh",
        backgroundColor: 'red',
        width: "3vw",
        height: "3vw",
      }}
      icon={<DeleteOutlined />}
      onClick={handleDeletePolygon}
    />
    </>
  )
}

export default EditPolygon