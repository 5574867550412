import React from 'react'
import nawigacja1 from "../../../helpPhotos/nawigacja1.jpg";
import "./Nawigacja.css"
const Nawigacja = () => {
    return (
        <div className='help-navigation-container'>
        <h2 className='help-navigation-title'>Ogólna nawigacja</h2>

        <div className='help-navigation-content'>
            <img className="help-navigation-img"src={nawigacja1} alt="ogolna nawigacja" />
            <article className="help-navigation-details">
                <h3>Opisy poszczególnych elementów:</h3>
                <ol>
                    <li><strong>Menu:</strong> Umożliwia wybór elementu, który chcemy dodać na obszar roboczy oraz aktualnie aktywnej sekcji</li>
                    <li><strong>Okno aktywnej sekcji:</strong> Wyświetla informację na temat aktualnie aktywnej sekcji oraz zawiera przyciski odpowiadające za rysowanie rury oraz usuwania sekcji   </li>
                    <li><strong>Okno edycji zraszacza:</strong> Pozwala na edytowanie parametrów wybranego zraszacza takich jak kąt, dysza, rotacja oraz zasięg.</li>
                    <li><strong>Przycisk podsumowania projektu:</strong> Umożliwia wygenerowanie specyfikacji projektu wraz ze spisem potrzebnych materiałów, który możemy pobrać</li>
                    <li><strong>Obszar roboczy:</strong> Główne pole, w której tworzymy oraz modyfikujemy projekt. To tutaj dodajemy, modyfikujemy oraz usuwamy elementy projektu</li>
                </ol>
            </article>
            <div className='help-navigation-footer'>
                <p>Wskazówka: Możesz przybliżać oraz oddalać obszar roboczy, przy użyciu kółka myszy</p>
            </div>
        </div>
        </div>
    )
}

export default Nawigacja