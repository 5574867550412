import React from 'react';
import MenuItem from './subcomponents/MenuItem';
import "./Menu.css"

import projektowanieIcon from "../../../icons/menu-projektowanie.png"
import katalogIcon from "../../../icons/menu-katalog.png";
import pomocIcon from "../../../icons/menu-pomoc.png";
const Menu = () => {

    return (
        <div className='menu-wrapper'>
            <MenuItem title={"Projektowanie"} description={"Aplikacja, dzięki której zaprojektujesz swój własny system nawadniania"} icon={projektowanieIcon} link={"/project"}/>
            <MenuItem title={"Katalog"} description={"Katalog podzespołów, w którym znajdziesz wszystkie niezbędne informacje na ich temat"} icon={katalogIcon} link={"/catalog"}/>
            <MenuItem title={"Sekcja pomocy"} description={"Sekcja, w której poznasz wszystkie podstawy tworzenia systemów nawadniania "} icon={pomocIcon} link={"/help"}/>
        </div>
    )
}


export default Menu;