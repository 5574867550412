import React, {useContext, useState} from 'react'
import { StoreContext } from '../../../../../../Store/StoreProvider';
import { Circle, Group, Label, Wedge, Text } from 'react-konva';



const Sprinklers = ({setSelectedSprinkler}) => {

    const {selectedSprinkler, isPipeDrawing, gridSize, sprinklers, setSprinklers, selectedPolygon, selectedSection} = useContext(StoreContext);
    const [isDragging, setIsDragging] = useState(false);



      
    
      const handleSprinklerClick = (sprinkler) => {
        if(!isDragging && !selectedPolygon && !isPipeDrawing){
          setSelectedSprinkler(sprinkler)
        }
      };
    
      const handleDragStart = () => {
        setIsDragging(true);
      }
    
    
      const handlerDragEnd = (e, sprinklerId) => {
        setIsDragging(false);
        const {x, y} = e.target.position();
        const updatedSprinklers = sprinklers.map((sprinkler) =>
          sprinkler.id === sprinklerId ? {...sprinkler, x, y} : sprinkler
        );
    
        setSprinklers(updatedSprinklers);
        setSelectedSprinkler(null);
      }
  return (
    <>
    {
            sprinklers.map((sprinkler) => (
              <Group
                key={sprinkler.id}
                x={sprinkler.x}
                y={sprinkler.y}
                draggable
                onDragStart={handleDragStart}
                onDragEnd={(e) => handlerDragEnd(e, sprinkler.id)}
                onClick={() => handleSprinklerClick(sprinkler)}
              >
              
              
              <Wedge
                radius={sprinkler.range * gridSize }
                angle ={sprinkler.angle}
                fill={sprinkler.section === selectedSection.name ?  sprinkler.color : "white"}
                stroke="black"
                strokeWidth={3}
              
                opacity={selectedSprinkler === sprinkler ? 0.7 : 0.3}
                rotation={sprinkler.rotation}
              />
              <Circle
                radius = {selectedSprinkler === sprinkler ? 0.5 * gridSize : 0.4 * gridSize}
                fill={sprinkler.color}
                stroke={"black"}
                strokeWidth={2}
              />
              
              <Label
                fill={'black'}
              >
                <Text 
                    text={sprinkler.name}
                    fontFamily='Arial'
                    fontSize={24}                    
                    padding={10}
                    fill={selectedSprinkler === sprinkler ? "red" : 'white'}
                    align='center'
                    stroke={"black"}
                    strokeWidth={1}

                />
              </Label>

              </Group>
            ))
          }

          
    </>
  )
}

export default Sprinklers