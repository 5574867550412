import React from "react";
import "./Header.css";


import { Link } from "react-router-dom";
import HeaderNavigation from "./subcomponents/HeaderNavigation/HeaderNavigation";
const Header = () =>{


    return (
        <>
        <div className="header-main">
            <Link to="/" className="site-name">JBMproject.pl</Link>
            <HeaderNavigation/>
        </div>
        </>
    )
};


export default Header;