import React from 'react';
import { Link } from 'react-router-dom';

const MenuItem = ({title, description, icon, link}) => {

    return (
        <div className='menu-item'>
            
          <img className='menu-item-img'src={icon} alt={title} />
            <Link to={link} className='menu-item-title'>{title}</Link>
            <p className='menu-item-description'>{description}</p>
        </div>
    )
}

export default MenuItem;