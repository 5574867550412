import React, { useContext, useEffect, useState } from 'react'
import { Circle, Group, Line, Text } from 'react-konva'
import { StoreContext } from '../../../../../Store/StoreProvider';

const Pipes = ({pipes, selectedSection, setFirstPoint,  distanceLine, firstPoint}) => {


    const {gridSize, setFirstPointPipe, setActualPipePoints, isPipeDrawing} = useContext(StoreContext);
    
    
    const [actualDistance, setActualDistance] = useState(null);
    const [actualTextPosition, setActualTextPosition] = useState({ x: 0, y: 0 });
    
    const pipesInSection = pipes.filter(pipe => pipe.section === selectedSection.name);
    const lastPipe = pipesInSection.length > 0 ? pipesInSection[pipesInSection.length - 1] : null;

    const handleCircleOnClick = () => {
        setFirstPointPipe(true);
        setActualPipePoints([lastPipe.points[2], lastPipe.points[3]])
        setFirstPoint({x: lastPipe.points[2], y:lastPipe.points[3] })
    }
    useEffect(() => {
        if (distanceLine.length > 0 && isPipeDrawing) {
          const midX = (distanceLine[0] + distanceLine[2]) / 2;
          const midY = (distanceLine[1] + distanceLine[3]) / 2;
          const deltaX = distanceLine[2] - distanceLine[0];
          const deltaY = distanceLine[3] - distanceLine[1];
          const length = (Math.sqrt(deltaX * deltaX + deltaY * deltaY) / gridSize).toFixed(2);
    
          setActualTextPosition({ x: midX, y: midY });
          setActualDistance(length);
        } else {
          setActualDistance(null);
        }
      }, [distanceLine, isPipeDrawing]);


  return (
    <>

    {pipesInSection.map((pipe) => (
      <>
        <Line
        points={pipe.points}
        stroke="black"
        strokeWidth={7}
      />

        <Line
          points={pipe.points}
          stroke={selectedSection.color}
          strokeWidth={3}
        />
      </>
      ))}

    {isPipeDrawing && distanceLine.length > 0 && firstPoint && (
        <Group>
          <Line
            points={distanceLine}
            stroke={'blue'}
            strokeWidth={4}
            dash={[10, 10]}
          />
          {actualDistance && (
    
        <Text
              x={actualTextPosition.x}
              y={actualTextPosition.y}
              text={`${actualDistance} m`}
              fontSize={24}
              fill={'red'}
            />
          )}
        </Group>
      )}

      {lastPipe && lastPipe.points.length >= 4 && isPipeDrawing && (
        <Circle
            x={lastPipe.points[2]}
            y={lastPipe.points[3]}
            radius={10}
            fill={"red"}
            stroke={'black'}
            strokeWidth={4}
            onClick={handleCircleOnClick}
            
            />
      )}

    </>
  )
}

export default Pipes

