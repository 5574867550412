
import { Slider, Row, Col, InputNumber, Button, Cascader, message } from 'antd'
import React, {act, useContext, useEffect, useState} from 'react'
import "./EditSprinkler.css"
import { StoreContext } from '../../../../../../Store/StoreProvider'




const EditSprinkler = ({selectedSprinkler, setSelectedSprinkler}) => {


    const {updateSections, sprinklers, setSprinklers, sections, updateSprinkler, selectedSection, waterSourceParams } = useContext(StoreContext);  


    const options = sections.map((section) => {
        return({
        value: section.name,
        label: section.name,
        })
    })

    const dysze = selectedSprinkler.dysze;
    const dyszeOptions = dysze.map((dysza) => {
        return({
            value: dysza.number,
            label: dysza.number,
        })
    })

    const handleSectionChange = (value) =>{     
        
        const newSection = sections.find((section) => section.name === value[0])

        

        if((newSection.waterNeed + selectedSprinkler.actualDysza.waterNeed) > 0.8 * waterSourceParams){
            message.open({
                type: 'error',
                content: 'Zmiana sekcji spowoduje wykorzystanie ponad 80% przepływu źródła przez sekcję',
                style: {
                  fontSize: '24px'
                }
            })

            return;
        }

        const actualSprinklerSection = sections.find((section) => section.name === selectedSprinkler.section);
        
        actualSprinklerSection.waterNeed = actualSprinklerSection.waterNeed - selectedSprinkler.actualDysza.waterNeed;
        updateSections(actualSprinklerSection);
        
        selectedSprinkler.section = value[0];

        
        
        selectedSprinkler.color = newSection.color;

        

        newSection.waterNeed += selectedSprinkler.actualDysza.waterNeed;

        updateSections(newSection);
        updateSprinkler(selectedSprinkler);



        
    }



    const sprinklerSetAngle = (newValue) => {
        selectedSprinkler.angle = newValue;
        updateSprinkler(selectedSprinkler);

    }

    const sprinklerSetRotation = (newValue) => {
        selectedSprinkler.rotation = newValue;
        updateSprinkler(selectedSprinkler);
    }


    const deleteSprinkler = () => {
        const newSprinklers = sprinklers.filter((sprinkler) => {
            return sprinkler.id !== selectedSprinkler.id;
        })


        const actualSprinklerSection = sections.find((section) => section.name === selectedSprinkler.section);


        actualSprinklerSection.waterNeed = actualSprinklerSection.waterNeed - selectedSprinkler.actualDysza.waterNeed;
        updateSections(actualSprinklerSection);
        setSprinklers(newSprinklers);
        setSelectedSprinkler(null);
    }



    const endEdit = () =>{
        setSelectedSprinkler(null);
        
        
    }


    const handleDyszaChange = (value) => {

        const numerDyszy = value[0];
        
        

        const actualDysza = selectedSprinkler.dysze.find((dysza) => dysza.number === numerDyszy);
        
        if((selectedSection.waterNeed - selectedSprinkler.actualDysza.waterNeed +  actualDysza.waterNeed) > 0.8 * waterSourceParams){
            message.open({
                type: 'error',
                content: 'Zmiana dyszy spowoduje wykorzystanie ponad 80% przepływu źródła przez sekcję',
                style: {
                  fontSize: '24px'
                }
            })

            return;
        }
        
        const actualSprinklerSection = sections.find((section) => section.name === selectedSprinkler.section);
        actualSprinklerSection.waterNeed = actualSprinklerSection.waterNeed - selectedSprinkler.actualDysza.waterNeed;
        updateSections(actualSprinklerSection);
        
        
        selectedSprinkler.range = actualDysza.range;
        selectedSprinkler.actualDysza = actualDysza;
        selectedSprinkler.reduction = 0;

        actualSprinklerSection.waterNeed += actualDysza.waterNeed;
        

        updateSections(selectedSection);
        updateSprinkler(selectedSprinkler);
        

    }

    const handleReductionChange = (newValue) => {
        selectedSprinkler.reduction = newValue;
        const newRange = selectedSprinkler.actualDysza.range * (1-(selectedSprinkler.reduction * 0.01));
        selectedSprinkler.range = newRange;
        updateSprinkler(selectedSprinkler);
    }
  return (
    <div className='edit-main'>
        <Row>
        
        <p>Sekcja</p>
    </Row>
    <Row>
        <Cascader 
            className='cascader-edit-sprinkler-section' 
            options={options} 
            onChange={handleSectionChange} 
            placeholder={selectedSprinkler.section}
            placement='topRight' />
    
    </Row>
    <p>Wybierz kąt zraszacza</p>
        <Row>
            <Col span={17}>
            <Slider
                min={selectedSprinkler.minAngle}
                max={selectedSprinkler.maxAngle}
                value={selectedSprinkler.angle}
                onChange={sprinklerSetAngle}
            />
            </Col>
            <Col span={5}>
            <InputNumber
                min={selectedSprinkler.minAngle}
                max={selectedSprinkler.maxAngle}
                style={{ margin: '0 16px' }}
                value={selectedSprinkler.angle}
                onChange={sprinklerSetAngle}
            />
            </Col>
        </Row>

  
  
    <p>Rotacja zraszacza</p>
    <Row>
        <Col span={17}>
        <Slider 
            min={0}
            max={360}
            value={selectedSprinkler.rotation}
            onChange={sprinklerSetRotation}
        />
        </Col>
        <Col span={5}>
        <InputNumber
          min={0}
          max={360}
          style={{ margin: '0 16px' }}
          value={selectedSprinkler.rotation}
          onChange={sprinklerSetRotation}
        />
    </Col>

    </Row>
    <p>Wybór dyszy</p>
    <Row>
    
        <Cascader 
            className='cascader-edit-sprinkler-section' 
            options={dyszeOptions} 
            onChange={handleDyszaChange} 
            placeholder={selectedSprinkler.actualDysza.number}
            placement='topRight' />
    </Row>
    <Row>
        <p>Zasięg [m]</p>
        </Row>
        <Row>
        <InputNumber
            value={selectedSprinkler.range.toFixed(2)}
            disabled
        />
    
    </Row>
    <Row>
        <p>Zapotrzebowanie na wodę [l/h]</p>
        </Row>
        <Row>
        <InputNumber
            value={selectedSprinkler.actualDysza.waterNeed}
            disabled
        />
    </Row>
    <Row>
        <p>Skrócenie zasięgu zraszacza [%]</p>
        </Row>
        <Row>
            <Col span={17}>
        <Slider
            min={0}
            max={5}
            value={selectedSprinkler.reduction}
            onChange={handleReductionChange}
        />
        </Col>
        <Col span={5}>
        <InputNumber
          min={0}
          max={5}
          style={{ margin: '0 16px' }}
          value={selectedSprinkler.reduction}
          onChange={handleReductionChange}
        />
    </Col>
    </Row>
    
    <p>Usuń zrasacz</p>
    <Button 
        danger 
        onClick={deleteSprinkler}>
            Usuń zraszacz
        </Button>
    <Button 
        className='end-button-edit-sprinkler'
        type="primary" 
        onClick={endEdit}>
            Zapisz zmiany
    </Button>
    </div>
  )
}

export default EditSprinkler