import React from 'react'
import SourceModal from './SourceModal/SourceModal';
import SizeModal from './SizeModal/SizeModal';
import "./Modals.css";
import AddSectionModal from "./AddSectionModal/AddSectionModal";
export const Modals = ({currentMenu, openModal, setOpenModal}) => {
  
    const handleClose = () => {
        setOpenModal(false);
    };


    return (
        <>
        {currentMenu === 'zrodlo' && (
            <SourceModal className='modal-project' open={openModal} onClose={handleClose} />
        )}
        {currentMenu === 'wymiary' && (
            <SizeModal className='modal-project' open={openModal} onClose={handleClose} />
        )}
        {currentMenu === 'addSection' && (
            <AddSectionModal className='modal-project' open={openModal} onClose={handleClose} />
        )}
        
        
        </>
    )
}

export default Modals;
