import React from 'react';
import DodanieZraszacza from './DodawanieElementowSub/DodanieZraszacza';
import DodawanieBudynku from "./DodawanieElementowSub/DodawanieBudynku";
import DodawaniePodloza from './DodawanieElementowSub/DodawaniePodloza';
const DodawanieElementow = ({ selectedSubcategory }) => {
    return (
        <div>
        <h3>{selectedSubcategory}</h3>
        {selectedSubcategory === 'Dodawanie zraszaczy' && (
            <DodanieZraszacza/>
        )}
        {selectedSubcategory === 'Dodawanie budynków' && (
            <DodawanieBudynku/>
        )}
        {selectedSubcategory === 'Wstawianie nawierzchni' && (
            <DodawaniePodloza/>
        )}
    </div>
    );
};

export default DodawanieElementow;
